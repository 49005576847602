import StoryDetailsBlock from "../../display/storydetailsblock";
import React from "react";
import WikipediaReference from "../../../bookingdetails/edit/wikipediareference";
import {histomania_login_user} from "../../../histomaniamain";
import AddStoryDescriptor from "./addstorydescriptor/addstorydescriptor";
import i18n from "../../../i18n/i18n";

const WikipediaInput = ({innerWidth, wikipage, setWikipage, addstorydescriptor, setAddstorydescriptor, decorationDescriptor, setDecorationDescriptor, bgColor}) => {
  return <StoryDetailsBlock name={i18n("wikipediainput.js705594377")} width={innerWidth} color={bgColor} help={'index.html#_inhalte'}>
    <WikipediaReference wikipage={wikipage}
                        onChange={(wikipage) => setWikipage(wikipage)}
    />
    <div style={{height: 15}}/>
    {histomania_login_user === "info@histomania.de" &&
        <AddStoryDescriptor addstorydescriptor={addstorydescriptor}
                            onChange={(addstorydescriptor) => setAddstorydescriptor(addstorydescriptor)}
        />}
  </StoryDetailsBlock>
}

export default WikipediaInput;
