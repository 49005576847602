import React from "react";
import Typography from '@material-ui/core/Typography';
import i18n from "../../i18n/i18n";
import LockIcon from "@material-ui/icons/Lock";
import Flag from "../../ui-components/flag";

const MoreItem = (props) => {
    const { onClick, backdrop, locked, preTitle, title, overview, imgLicense, wikiImg, language } = props;

    return (
        <div className="MoreItem" onClick={() => onClick && onClick()}>
            {backdrop && (
                <div className="SmallItem">
                    <div
                        style={{backgroundImage: `url('${backdrop}')`}}
                        className="InnerItem"
                    />
                </div>
            )}

            <div style={{position: "relative"}}>
                {locked && (
                    <div className="title" style={{position: "absolute", right: 10}}>
                        <LockIcon style={{fontSize: 35}}/>
                    </div>
                )}
                <Typography variant="body1">{preTitle}</Typography>
                <Typography variant="subheading">{title}</Typography>
                <Typography variant="body1">{overview}</Typography>
                <div className="ImageLicense">
                    {imgLicense && imgLicense !== '' && (
                        <div
                            className="ImageLicense"
                            dangerouslySetInnerHTML={{
                                __html: `<div><a href='https://commons.wikimedia.org/wiki/File:${wikiImg}' rel='nofollow noopener noreferrer' target='_blank'>${i18n("image")}:</a> ${imgLicense}</div>`
                            }}
                        />
                    )}
                </div>
            </div>
            <div className="language">
                <Flag language={props.language}/>
            </div>
        </div>
    );
};

export default MoreItem;
