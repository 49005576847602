import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as Ukraine } from "../images/icons/flags/ukraine.svg";
import { ReactComponent as UK } from "../images/icons/flags/uk.svg";
import { ReactComponent as Germany } from "../images/icons/flags/germany.svg";
import { ReactComponent as Italy } from "../images/icons/flags/italy.svg";
import { ReactComponent as France } from "../images/icons/flags/france.svg";

import React from "react";

const Flag = ({language}) => {
    var item;
    switch (language) {
        case "en":  item = <UK/>; break;
        case "it":  item = <Italy/>; break;
        case "fr":  item = <France/>; break;
        case "ua":  item = <Ukraine/>; break;
        default: item = <Germany/>;
    }

    return  <SvgIcon viewBox="0 0 1000 500">
        {item}
    </SvgIcon>
}

export default Flag;
