import React from 'react';
import 'whatwg-fetch';
import Editor from "@draft-js-plugins/editor";

class EditorWrapper extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

  render() {
    const blockStyleFn = (contentBlock) => {
      const type = contentBlock.getType();
      if (type === 'header-one') {
        return 'custom-h1';
      } else if (type === 'header-two') {
        return 'custom-h2';
      } else if (type === 'header-three') {
        return 'custom-h3';
      }
    };

    return <Editor
            key={this.props.readOnly}
            editorState={this.props.editorState}
            onChange={this.props.onChange}
            plugins={this.props.plugins}
            readOnly={this.props.readOnly}
            ref={this.props.innerRef}
            blockStyleFn={blockStyleFn}
    />

  }
}

export default EditorWrapper;
