import React, {memo} from "react";
import MediaFullScreenWrapper from "../../../media/mediaFullScreenWrapper";

const Image = ({src, alt, title, style}) => {
  return <MediaFullScreenWrapper style={{overflow: 'hidden'}} childs={fullScreen => {

    const myStyle = fullScreen ? {...style, width: 'inherit', height: '100vH'} : style;

    return <span style={{display: "flex", justifyContent: "center"}}><img src={src} alt={alt} style={myStyle}/></span>
  }}/>
}

export default memo(Image);
