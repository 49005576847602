import React from "react";
import "whatwg-fetch";
import Config from "./config/config.js";
import {
    Helper,
    InstrumentedTimeline,
    LCal,
    LCalHelper,
    LCalInterval,
    SliderHelper
} from "react-canvas-timeline";

import LCalDateField from './ui-components/lib/datetimepicker/lcaldatefield';
import styles from './ui-components/lib/styles';
import HistoModel from "./model/histomodel";
import {
    parseJSONListToStories,
    parseJSONListToTask,
    parseJSONStory,
    parseJSONTask
} from "./parser";
import Login from "./login.js";

import TransferHandler from "./transfer/transferhandler.js";
import MemberEntry from "./data/memberentry";
import Snackbar from "@material-ui/core/Snackbar";
import {Set} from 'immutable';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Drawer from "@material-ui/core/Drawer";
import {
    ThemeProvider as MuiThemeProvider,
    withStyles
} from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Story from "./data/story";
import {displayPage} from "./displaypage";
import Header from './header/header';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import SplitPane from "react-split-pane";
import {OnLoadComponent} from "./specialfunctionexecutor";
import Popover from '@material-ui/core/Popover';
import getMinStartMaxEnd from './utils/minmaxcomputation'
import PrintLoader from './printing/printloader';
import {ReactComponent as Arrow} from "./images/icons/arrow.svg";
import timelineConfig from "./styling/timelineconfig";
import getParameterByName from "./utils/parameterbyname";
import theme from './theme.js';
import {getImgSrc, getIconProvider} from "./images/imgsrc";
import waitStyle from './styling/waitstyle';
import ListMenu from './menu/menu';
import circularMenu from './menu/circularmenu';
import './styling/circularMenu.scss';
import './styling/splitpane.scss';
import HistoSuspense from "./histosuspense";
import CookieConsent from "./cookies/cookieconsent";
import {innerEvents2json} from "./bookingdetails/edit/innerevents";
import Typography from "@material-ui/core/Typography";
import HtmlTooltip from "./styling/htmltooltip";
import SettingsOverscanTwoToneIcon from '@material-ui/icons/SettingsOverscanTwoTone';
import CircularProgress from '@material-ui/core/CircularProgress';
import HintDialog from "./ui-components/hintdialog";
import CancelEditDialog from "./ui-components/canceleditdialog";
import OKButton from "./ui-components/buttons/text/okbutton";
import StoryDetailsMain from "./resdetails/storydetailsmain";
import MainFab from './ui-components/buttons/text/mainFab';
import MaintenanceEventList from "./eventtable/maintenanceEventList";
import {containsBuzzword} from "./filter/filtertasklist";
import Welcome from "./welcome/welcome";
import MediaFullScreenWrapper from "./media/mediaFullScreenWrapper";
import MeasureHeader from "./measure/measureHeader";
import i18n from "./i18n/i18n";
import LanguageMenu from "./menu/languagemenu";
import {findObjectsWithType} from "./utils/findobjectswithtype";
const Search = React.lazy(() => import('./search/search.js'));
const BookingDetails = React.lazy(() => import('./bookingdetails/bookingdetails.js'));
const SourceReference = React.lazy(() => import('./sourcereference'));
const EditProfile = React.lazy(() => import('./editprofile.js'));
const ChangePassword = React.lazy(() => import('./changepassword.js'));
const AdminLicenses = React.lazy(() => import('./licenseadmin/adminlicenses.js'));
const MapDialog = React.lazy(() => import('./mapdialog'));
const Bookmarks = React.lazy(() => import('./bookmarks/bookmarks.js'));
const FilterTimeline = React.lazy(() => import('./filter/filtertimeline'));
const MoveMeasureSliderDialog = React.lazy(() => import('./ui-components/movemeasuresliderdialog'));
const WhatIfDialog = React.lazy(() => import('./ui-components/whatifdialog'));
const ResourceChooserDialog = React.lazy(() => import('./resourcechooserdialog'));
const ReadmeDialog = React.lazy(() => import('./readmedialog'));
const Import = React.lazy(() => import('./import'));

const Settings = React.lazy(() => import('./settings.js'));
const Export = React.lazy(() => import('./export'));
const Embed = React.lazy(() => import('./embed.js'));
const QRCode = React.lazy(() => import('./qrcodepage.js'));
const Details = React.lazy(() => import('./ui-components/details'));

export let histomania_sessionid;
export let histomania_licenseid;
export let histomania_login_user;
export let histomania_login_user_is_dummy = true;
export let histomania_login_user_has_newsletter = false;

//Falls in window.globalStoryID etwas drin steht (weil es über php in die index.php geschrieben wurde),
//dann wird das in die URL geschrieben
/*if(window.globalStoryID && window.globalStoryID != '') {
    window.history.pushState(null, null,
        "/app/?stories="
        + window.globalStoryID);
}*/

let dseContent = "";
let agbContent = "";
let impressumContent = "";

const language = window.localStorage.getItem("histomania_language");
if(!language) {
     let navLang = (window.navigator.languages && window.navigator.languages[0]) || // Chrome / Firefox
         window.navigator.language;
     if(navLang.includes("de")) {
         window.localStorage.setItem("histomania_language", "de");
     } else if(navLang.includes("en")) {
        window.localStorage.setItem("histomania_language", "en");
     } else if(navLang.includes("it")) {
        window.localStorage.setItem("histomania_language", "it");
     } else if(navLang.includes("fr")) {
        window.localStorage.setItem("histomania_language", "fr");
     } else if(navLang.includes("ua")) {
         window.localStorage.setItem("histomania_language", "ua");
     } else {
         window.localStorage.setItem("histomania_language", "");
     }
}


//Hat die URL einen Parameter, mit dem angegeben wird, welche Geschichten angezeigt werden sollen? -> Dann die Geschichten visible machen
//const stories = getParameterByName("stories");
const noheader = getParameterByName("noheader") === 'true';
const noarticle = getParameterByName("noarticle") === 'true';
const backgroundTheme = getParameterByName("backgroundTheme");
const nofab = getParameterByName("nofab") === 'true';
const bgImage = getParameterByName("bgImage");

const MIN_BAR_HEIGHT = 30;
const MAX_BAR_HEIGHT = 150;

const specialFunction = getParameterByName("specialfunction");

const getDefaultSplitDividerPos = (orientation, headerheight) => {
    if(noarticle) {
        return 0;
    } else if(orientation === "vertical") {
        return window.innerWidth * 0.3;
    } else {
        return (window.innerHeight - headerheight) * 0.666;
    }
}

const isTimetableVisible = (orientation, dividerpos, headerheight) => {
    if(orientation === "vertical") {
        return dividerpos < window.innerWidth;
    }
    return dividerpos <  window.innerHeight -  headerheight;
}

class HistomaniaMain extends React.Component {
    constructor(props) {
        super(props);
        this.handleResize = this.handleResize.bind(this);
        this.login = this.login.bind(this);
        this.loggedIn = this.loggedIn.bind(this);
        this.editRights = this.editRights.bind(this);
        this.editProfile = this.editProfile.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.adminLicenses = this.adminLicenses.bind(this);
        this.settings = this.settings.bind(this);
        this.exportData = this.exportData.bind(this);
        this.importData = this.importData.bind(this);
        this.embedStories = this.embedStories.bind(this);
        this.qrcode = this.qrcode.bind(this);
        this.taskDetails = this.taskDetails.bind(this);
        this.resourceDetails = this.resourceDetails.bind(this);
        this.saveTasks = this.saveTasks.bind(this);
        this.startPollChanges = this.startPollChanges.bind(this);
        this.pollChanges = this.pollChanges.bind(this);
        this.initNewResource = this.initNewResource.bind(this);
        this.measure = this.measure.bind(this);
        this.bookmarks = this.bookmarks.bind(this);
        this.filterTimeline = this.filterTimeline.bind(this);
        this.printDialog = this.printDialog.bind(this);
        this.dataprotection = this.dataprotection.bind(this);
        this.agb = this.agb.bind(this);
        this.impressum = this.impressum.bind(this);
        this.sourceReference  = this.sourceReference.bind(this);
        this.createBookingItemFromScratch = this.createBookingItemFromScratch.bind(this);
        this.createBookingItemFromClick = this.createBookingItemFromClick.bind(this);
        this.search = this.search.bind(this);
        this.searchStory = this.searchStory.bind(this);
        this.toggleCollapseGroups = this.toggleCollapseGroups.bind(this);
        this.showMapDialog = this.showMapDialog.bind(this);
        this.mapForTimeInterval = this.mapForTimeInterval.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.checkHiStoryLanguages = this.checkHiStoryLanguages.bind(this);

        this.instrumentedtimeline = null;
        this.timelineDIV = React.createRef();

        this.pageHistory = [];
        this.mouseMoveHandle = 0;
        this.icons = [];
        this.canOpenContextMenu = true;

        this.journalid = 0;

        this.decorationdescriptorBeforeEdit = null;

        this.state = {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            headerHeight: this.getHeaderHeight(),
            userLoggedIn: false,
            loadingData: false,
            savingData: false,
            selectedResource: null,
            activeDetailPage: "",
            detailPageAbortable: true,
            snackbarMessage: "",
            snackbarAutoClose: true,
            menuAnchorEl: null,
            languageMenuAnchorEl: null,
            menuOpen: false,
            languageMenuOpen: false,
            openMainMenuItem: "",
            pendingResourceIDs: new Set(),
            measureStart: null,
            measureEnd: null,
            currentMeasureInterval: null,
            currentMeasureResult: null,
            detailViewType: "drawer",
            maxLabelLength: "full",
            orientationType: "horizontal",
            showTimelineBackgroundImage: true,
            searchPhrase: "",
            searchGeoLon: "",
            searchGeoLat: "",
            searchGeoType: "",
            activeSearchTab: "",
            taskEditMode: false,
            storyEditMode: "",
            storyEditModeID: -1,
            waitOverlayOnDetails: false,
            cancelEditTaskDialogOpen: false,
            cancelEditResourceDialogOpen: false,
            whatIfResID: 0,
            whatIfStart: null,
            moveMeasureSliderDialogIsVisible: false,
            moveMeasureSliderDialogTime: null,
            activeMeasureSliderIsStart: true,
            activeMeasureSliderPastFuture: "past",
            initialLoginCard: "login",
            warnDialogType: "warning",
            dialogWarningText: "",
            canPressOKInWarningDialog: true,
            dlgOpen: false,
            resChooserDlgOpen: false,
            dlgContent: '',
            contextMenuTime: null,
            contextMenuResource: null,
            contextMenuOpen: false,
            contextMenuX: 0,
            contextMenuY: 0,
            lastDataSaveTime: 0,
            splitPaneDividerPos: getDefaultSplitDividerPos("horizontal", this.getHeaderHeight()),
            printDialogOpen: false,
            mapDialogConfig: null,
            lastLoadedResources: null,
            firstDataLoaded: false,
            measureDurationLock: false,
            jumpToDatePopupOpen: false,
            jumpLCal: null,
            hasError: false,
            printModel: null,
            showCookieConsent: window.showCookieConsent && !window.isSchoolDomain && !localStorage.getItem("histomania_cookies_accepted"),
            showSpecialFunction: !!specialFunction,
            currentStoryToSave:null,
            showPricing:false
        };

        this.model = new HistoModel();
        this.model.getResourceModel().addDataChangeCallback(
            () => {
                this.forceUpdate();
            }
        );
        this.model.addDataChangeCallback(
            (type) => {
                if("FILTER"===type) {
                    this.forceUpdate();
                }
            }
        );

        /*window.addEventListener("keydown", function (e) {
            if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
                this.search();
                e.preventDefault();
            }
        }.bind(this));*/

        window.addEventListener('popstate', function(event) {
            window.location.href = event.target.location.href;
        });

        //Auf einen hashchange wegen Verhinderung des Beendens der Anwendung lauschen
        /*if (window.history.pushState) {
            window.history.replaceState(null, null, '#2');
            window.history.pushState(null, null, '#1');
        }*/
        /*window.addEventListener("hashchange", (evt) => {
            if(window.location.hash !== '') {
                if (this.state.activeDetailPage !== "") {
                    this.closeDetails();
                    //window.history.pushState(null, null, '#1');
                    window.location.hash = '';
                } else if (this.state.dlgOpen) {
                    //Anhand der URL prüfen, ob es sich um einen intern gedrückten Link handelt, falls nicht, dann Fenster schließen
                    if (!isNaN(parseInt(window.location.href.substr(-1)))) {
                        this.setState({dlgOpen: false});
                    }
                    //window.history.replaceState(null, null, '#2');
                    //window.history.pushState(null, null, '#1');
                    window.location.hash = '';
                }
            }
        })*/

        window.addEventListener('resize', this.handleResize);

        let iconProvider = getIconProvider(this.icons);
        iconProvider.bind(this);

        this.model.getResourceModel().setIconProvider(iconProvider);
        this.model.setIconProvider(iconProvider);

        this.onTimelineClick = this.onTimelineClick.bind(this);
        this.onTimelinePress = this.onTimelinePress.bind(this);
        this.onTimelineLongPress = this.onTimelineLongPress.bind(this);
    }

    getHeaderHeight() {
        return noheader ? 0 : (this.model && this.model.getResourceModel().size() > 0 ? 120 : 70);
    }

    refreshHeaderHeight() {
        this.setState({headerHeight: this.getHeaderHeight()})
    }

    getSelectedTaskID() {
        if (this.model.getSelectedItemIDs().length > 0) {
            return this.model.getSelectedItemIDs()[0];
        }
        return 0;
    }

    getSelectedTask() {
        if (this.model.getSelectedItemIDs().length > 0) {
            const item = this.model.getItemByID(this.model.getSelectedItemIDs()[0]);
            return item;
        }
        return null;
    }

    setSelectedTask(task) {
        if (task) {
            this.model.setSelectedItemIDs([task.getID()]);
        } else {
            this.model.setSelectedItemIDs([]);
        }
    }

    handleResize() {
        this.model._setDisplayDataDirty(true);
        this.setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        });
    }

    componentDidMount() {
        //Automatischer Login?
        this.autoLogin();
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error);
        console.log(errorInfo);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    onTimelineClick(timelineevent) {
        if (this.state.contextMenuOpen) {
            this.setState({
                contextMenuOpen: false
            });
            this.canOpenContextMenu = false;
        }
        if (timelineevent.mouseOverStartMeasureSlider) {
            this.setState({
                    moveMeasureSliderDialogIsVisible: true,
                    activeMeasureSliderIsStart: true,
                    moveMeasureSliderDialogTime: this.state.measureStart,
                    activeMeasureSliderPastFuture: "past"
                }
            );
        } else if (timelineevent.mouseOverEndMeasureSlider) {
            this.setState({
                moveMeasureSliderDialogIsVisible: true,
                activeMeasureSliderIsStart: false,
                moveMeasureSliderDialogTime: this.state.measureEnd,
                activeMeasureSliderPastFuture: "future"
            });
        } else if (!timelineevent.isTimeHeaderPressed()) {
            if (timelineevent.getTask() !== null) {
                //Öffnen der Detailseite zum Editieren
                if(timelineevent.getTask()) {
                    let task = timelineevent.getTask();
                    this.taskDetails(task);
                }
            } else if (timelineevent.isResourceCheckboxPressed()) {
                //Story schießen
                if(timelineevent.getResource()) {
                    this.toggleResourceVisibility(timelineevent.getResource().getID());
                }
            } else if (timelineevent.isResourceHeaderPressed()) {
                //Öffnen der Detailseite zum Editieren
                let res = timelineevent.getResource();
                if (res ) {
                    this.resourceDetails(res);
                }
            } else {
                //Irgendwohin drücken bedeutet immer die Details zu schließen, falls dieses noch offen ist
                if (!this.model.getSelectedItemIDs() || this.model.getSelectedItemIDs().length === 0) {
                    if (this.canOpenContextMenu && !this.state.contextMenuOpen) {
                        this.setState({
                            contextMenuResource: timelineevent.getResource(),
                            contextMenuTime: timelineevent.getTime(),
                            contextMenuOpen: true,
                            contextMenuX: timelineevent.getAbsX(),
                            contextMenuY: timelineevent.getAbsY()
                        });
                    }
                } else {
                    this.model.setSelectedItemIDs([]);
                }
            }
        }
    }

    onTimelinePress(timelineevent) {
        //Falls zu diesem Zeitpunkt ein Vorgang Detailreiterseiten geöffnet waren, dann kann kein Kontextmenü geöffnet werden
        if (this.state.activeDetailPage === "" && !timelineevent.getPressedBarGroupHeader()) {
            this.canOpenContextMenu = true;
        } else {
            this.canOpenContextMenu = false;
        }

        //Irgendwohin drücken bedeutet den springe-zu-Dialog zu schließen
        if (this.state.activeDetailPage !== "") {
            if (timelineevent.getTask() === null) {
                this.closeDetails();
            }
        }
    }

    onTimelineLongPress(timelineevent) {
        //Wurde weder der TimelineHeader noch der Resorucenheader gedrückt, aber wurde trotzdem kein bestehendes Task geklickt?
        if (!(timelineevent.isTimeHeaderPressed() || timelineevent.isResourceHeaderPressed() || timelineevent.isResourceCheckboxPressed() || timelineevent.getTask() !== null || timelineevent.getResource() === null)) {
            //this.createBookingItemFromClick(timelineevent.getResource(), timelineevent.getTime(), true);
            this.onTimelineClick(timelineevent);
        } else {
            //Langer Klick auf einen Vorgang: Kontextmenü
            if (timelineevent.getTask()) {
                let start = this.model.getDisplayedStart(timelineevent.getTask());
                let end = this.model.getDisplayedEnd(timelineevent.getTask());
                this.setState({measureStart: start, measureEnd: end});
            }
        }
    }

    createBookingItemFromClick(resource, time, pointInTime) {
        if (resource && resource.hasAdminRights()) {
            //Zeit auf Viertelstundengrenzen runden
            //issue #301: Neuer Termin Anlegen - Einrastverhalten
            let t = this.state.measureStart || time;
            t = t.clone();
            t.setTimeZone("Europe/Berlin");
            t.setPrecision(11);
            let tEnd;
            if (pointInTime) {
                t.setType(585);
            } else {
                t.setType(580);
                tEnd = this.state.measureEnd;
                if (tEnd) {
                    tEnd.setPrecision(11);
                    tEnd.setType(582);
                }
            }
            //Neue Task
            this.createBookingItem(resource.getID(), t, tEnd);
        }
    }

    createBookingItem(resID, t, tEnd, openDetailsForEdit=true) {
        if (!(t || tEnd)) {
            t = new LCal().initNow();
            t.setType(580);
        }
        //Neue Task
        let bi = this.model.createBookingItem(resID, t, tEnd);
        bi.name = i18n("histomaniamain.js781485964");
        this.model.add(bi);
        if(openDetailsForEdit) {
            this.taskDetails(bi);
            this.setState({taskEditMode: true, detailPageAbortable: false});
        }
        return bi;
    }

    newBookingItem(resID, openDetailsForEdit=true) {
        let t;
        if(isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
            let timelineStart = this.instrumentedtimeline.getStartTime().getJulianMinutes();
            let timelineEnd = this.instrumentedtimeline.getEndTime().getJulianMinutes();
            let avg = Math.round((timelineStart + timelineEnd) / 2);
            t = new LCal().setJulianMinutes(avg);
        } else {
            t = new LCal().setJulianMinutes(LCalHelper.getNowMinutes());
        }
        t.setTimeZone("Europe/Berlin");
        t.setPrecision(11);
        t.setType(585);
        return this.createBookingItem(resID, t, null, openDetailsForEdit);
    }

    createBookingItemFromScratch() {
        //Gibt es mehrere Stories, in die eingefügt werden kann?
        const adminRes = this.model.getResourceModel().getAdminResources();
        if(adminRes.length === 1) {
            this.newBookingItem(adminRes[0].id);
        } else if(adminRes.length > 1) {
            let resChooserCallback = (resID) => {
                this.setState({
                    resChooserDlgOpen: false
                });
                this.newBookingItem(resID);
            }
            this.setState({resChooserDlgOpen: true, resChooserCallback});
        } else {
            this.setState({dialogWarningText: i18n("histomaniamain.js702299662"), canPressOKInWarningDialog: false});
        }
    }

    openShare(url, hasPrivateStory, callback) {
        if(hasPrivateStory) {
            alert(i18n("histomaniamain.js197615179"));
        }
        callback && callback();
        window.open(url, '_blank');
    }

    autoLogin() {
        //Gibt es in den Parametern infos, die benutzt werden müssen?
        let remembermeKey = getParameterByName("autologinkey") || localStorage.getItem('histomania_rememberme_key');
        let rememberMeUser = getParameterByName("user") || localStorage.getItem('histomania_rememberme_user');

        if(!remembermeKey || remembermeKey.trim().length===0) {
            remembermeKey = 'auto'; //Serverseitig prüfen, ob der Benutzer noch in der Session steht
        }

        let transferObj = {
            email: rememberMeUser,
            remembermeKey: remembermeKey,
            width: window.innerWidth,
            height: window.innerHeight
        }

        let successFunc = (json) => {
            this.loggedIn(json.sessionid, json.licenseid, json.email, json.forename, json.surname, json.company, json.isdummy, json.hasnewsletter, json.setting_detailwindowtype, json.setting_maxlabellength, json.setting_orientationtype, json.setting_showTimelineBackgroundImage, json.setting_timelineBackgroundDark);
        }
        let errorFunc = (code, json) => {
            console.log("Kein automatischer Login möglich für "+remembermeKey);
            //this.setState({snackbarOpen: true, snackbarMessage: "Bitte prüfen Sie die Internetverbindung."});
            this.loginWithTempAccount();
        }

        TransferHandler.request(Config.getLoginURL(), successFunc, errorFunc, transferObj);
    }

    //Falls kein Autologin konfiguriert ist, dann wird erst mal mit einem temporären Benutzer gearbeitet
    //Dieser wird dann bei der Anmeldung oder nach einer bestimmten Zeit wieder gelöscht
    loginWithTempAccount() {
        let successFunc = (json) => {
            //Nicht in rememberme_key speichern, falls User in URL angegeben
            if (!getParameterByName("user")) {
                localStorage.setItem('histomania_rememberme_key', json.autologinkey);
                localStorage.setItem('histomania_rememberme_user', json.email);
            }

            this.loggedIn(json.sessionid, json.licenseid, json.email, json.forename, json.surname, json.company, true, false, json.setting_detailwindowtype, json.setting_maxlabellength, json.setting_orientationtype, json.setting_showTimelineBackgroundImage, json.setting_timelineBackgroundDark);
            //console.log("barSize after loginWithTempAccount: "+json.setting_barsize);
        }
        let errorFunc = (code, json) => {
            console.log("Fehler beim Erzeugen eines temporären Accounts");
            this.setState({snackbarMessage: "Bitte prüfe die Internetverbindung.", snackbarAutoClose: true});
            this.login();
        }

        TransferHandler.request(Config.getCreateTempAccountURL(), successFunc, errorFunc, {});
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }


    getBackgroundImage() {
        for (let res of this.model.getResourceModel().getAll()) {
            //das erstbeste Bild, falls dieses als Hintergrundbild freigegeben ist
            let imgSrc = getImgSrc(res);
            if (imgSrc) {
                return imgSrc;
            }
        }
    }

    login() {
        if (this.state.userLoggedIn) {
            //LogOut
            //Falls Remember-me gesetzt ist, dieses zurücksetzen
            localStorage.removeItem('histomania_rememberme_key');
            localStorage.removeItem('histomania_rememberme_user');
            histomania_sessionid = null;

            this.setState({userLoggedIn: false});
            this.closeDetails();
            this.model.getResourceModel().clear();
            this.model.clear();

            TransferHandler.request(Config.getLogoutURL(), null, null, null);

            //...und wieder den Anmeldedialog anzeigen
            this.setState({activeDetailPage: "login", initialLoginCard: "login", detailPageAbortable: false});
        } else {
            this.setState({activeDetailPage: "login", initialLoginCard: "login", detailPageAbortable: false});
        }
    }

    /*register() {
        this.setState({activeDetailPage: "login", initialLoginCard: "noAccount", detailPageAbortable: false});
    }*/

    loggedIn(sessionID, licenseid, email, forename, surname, company, isdummy, hasnewsletter, setting_detailwindowtype, setting_maxlabellength, setting_orientationtype, setting_showTimelineBackgroundImage, setting_timelineBackgroundDark) {
        histomania_login_user = email;
        histomania_login_user_is_dummy = !!isdummy;
        histomania_login_user_has_newsletter = !!hasnewsletter;
        histomania_sessionid = sessionID;
        histomania_licenseid = licenseid;

        if (this.state.activeDetailPage === "login") {
            this.closeDetails();
        }
        this.setState({userLoggedIn: true});

        this.detailViewTypeChanged(setting_detailwindowtype === 1 ? "drawer" : setting_detailwindowtype === 2 ? "overlay" : "auto");
        this.maxLabelLengthChanged(setting_maxlabellength === 0 ? "limited" : "full");
        this.onOrientationTypeChanged(setting_orientationtype === 1 ? "horizontal" : "vertical");
        this.onShowTimelineBackgroundImageChanged(setting_showTimelineBackgroundImage === 1 ? "true" : "false");
        this.onTimelineBackgroundDarkChanged(setting_timelineBackgroundDark === 1 ? "true" : "false");

        const entryPoint = getParameterByName("entryPoint");

        const f = function () {
            //Prüfen, ob das Profil vollständig ist. Ansonsten zum Bearbeiten des Profils springen
            if (forename && forename.length > 1 && surname && surname.length > 1) {
                if (entryPoint === "register") {
                    this.setState({activeDetailPage: "login", initialLoginCard: "noAccount", snackbarMessage: ""});
                } else if (entryPoint === "login") {
                    this.setState({activeDetailPage: "login", initialLoginCard: "login", snackbarMessage: ""});
                } else if (entryPoint === "search") {
                    this.setState({activeDetailPage: "search", snackbarMessage: ""});
                } else if (entryPoint === "create") {
                    this.initNewResource(new Story());
                    this.setState({snackbarMessage: ""});
                }
            } else {
                this.setState({activeDetailPage: "editProfile", detailPageAbortable: false});
            }


            this.modelToURL();

            this.checkHiStoryLanguages();
        }.bind(this);

        //Nach erfolgreichem Login müssen die Daten geladen werden (komplett, nicht nur Delta)
        this.loadAllData(f);
    }


    sessionIsInvalid() {
        this.setState({snackbarMessage: i18n("histomaniamain.sessionover"), snackbarAutoClose: true});
        this.autoLogin();
    }

    /**
     * Zoom vom kleinsten Datum bis zum größten Datum +- 10%
     */
    zoomToAll(animationCompletedCB) {
        if(isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
            this.forceUpdate(() => {
                let m = getMinStartMaxEnd(this.model);
                this.instrumentedtimeline && this.instrumentedtimeline && this.instrumentedtimeline.animateTo(m.minStart, m.maxEnd,
                    animationCompletedCB, true);
            });
        }
    }

    /**
     * Zoom vom kleinsten Datum bis zum größten Datum +- 10%
     */
    zoomTo(start, end, animationCompletedCB, doAnimate) {
        if(isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
            this.forceUpdate();
            this.instrumentedtimeline && this.instrumentedtimeline && this.instrumentedtimeline.animateTo(start, end,
                animationCompletedCB, doAnimate);
        }
    }

    animationCompleted(resources) {
        if (resources && resources.length > 0) {
            const res = resources[resources.length - 1];
            if(isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
                this.instrumentedtimeline.goToResource(res);
            }
        }
    }

    fitToScreen(minHeight, maxHeight, callback) {
        let myCallback = () => {
            let story = this.getSelectedStory();
            if(story) {
                setTimeout(()=> {
                    this.instrumentedtimeline && this.instrumentedtimeline.goToResource(story);
                }, 0);
            }
            callback && callback();
        }
        this.instrumentedtimeline && this.instrumentedtimeline.fitToScreen(minHeight, maxHeight, myCallback);
    }

    autoAdjustHeight(canReduceHeight, callback) {
        this.instrumentedtimeline.adjustHeight(20, null, MIN_BAR_HEIGHT, MAX_BAR_HEIGHT, callback);
    }

    loadAllData(onDataLoaded, resid) {
        this.setState({loadingData: true});
        let onSuccessLoadAll = (json) => {
            let resources = parseJSONListToStories(json.resources);
            let tasks = parseJSONListToTask(json.events);

            this.model.getResourceModel().setAll(resources);
            this.model.setAll(tasks);

            for(let resource of resources) {
                if(resource.collapsedgroups) {
                    for (let collapsedGroup of resource.collapsedgroups) {
                        this.model.addCollapsedGroup(
                            resource.id + "@" + collapsedGroup);
                    }
                }
            }

            this.journalid = json.journalid;

            //Die Anzahl der offenen und vorhandenen Mails aktualisieren
            this.setState({loadingData: false, firstDataLoaded: true, lastLoadedResources: resources}, () => {
                if (resources.length > 0) {
                    this.setSelectedResource(resources[0], ()=> {
                        this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT);
                        onDataLoaded && onDataLoaded();
                    });
                } else {
                    onDataLoaded && onDataLoaded();
                }
            });
        }

        let onSuccessLoadRes = (json) => {
            window.storyurl = null;
            let resources = parseJSONListToStories(json.resources);
            let tasks = parseJSONListToTask(json.events);

            this.model.getResourceModel().putAll(resources);
            this.model.putAll(tasks);

            for(let resource of resources) {
                if(resource.collapsedgroups) {
                    for (let collapsedGroup of resource.collapsedgroups) {
                        this.model.addCollapsedGroup(
                            resource.id + "@" + collapsedGroup);
                    }
                }
            }

            let callBack;
            if(isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
                callBack = ()=>this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT);
            }

            if (resources.length > 0) {
                this.setSelectedResource(resources[0], callBack);
            }

            this.setState({loadingData: false, lastLoadedResources: resources}, ()=>onDataLoaded && onDataLoaded());
        }

        let onError = (code, json) => {
            if (code === 500) {
                this.setState({snackbarMessage: i18n("histomaniamain.checkconnection"), snackbarAutoClose: true});
            } else if (code === 999) {
                this.sessionIsInvalid();
            }
            this.setState({loadingData: false});
        }

        //Falls eine Ressource angegeben wurde, dann nur diese Ressource laden
        if (resid) {
            //Eine Dummy-Ressource zum Laden setzen
            let res = new Story();
            res.setID(resid);
            res.setName(i18n("histomaniamain.isLoading"));
            res.secname = i18n("histomaniamain.pleasewait");
            this.model.getResourceModel().put(res);

            TransferHandler.request(Config.getLoadDataURL(), onSuccessLoadRes, onError, {initial: true, resid: resid});
        } else {
            const urlStories = getParameterByName("stories");
            if(urlStories) {
                TransferHandler.request(Config.getLoadDataURL(),
                    onSuccessLoadAll, onError,
                    {initial: true, resid: urlStories, exclusive: true});
            } else if(window.globalStoryID && window.globalStoryID !== '') {
                //Falls in window.globalStoryID etwas steht (weil über URL geladen wurde)
                TransferHandler.request(Config.getLoadDataURL(),
                    onSuccessLoadAll, onError,
                    {initial: true, resid: window.globalStoryID, exclusive: true});
            } else {
                TransferHandler.request(Config.getLoadDataURL(),
                    onSuccessLoadAll, onError,
                    {initial: true});
            }
        }
    }

    /**
     * Zyklischen Aufruf der Änderungsabfrage starten (nach erhalt des Response 10 Sekunden warten)
     */
    startPollChanges() {
        this.pollChanges(() => setTimeout(this.startPollChanges, 10000));
    }

    pollChanges(callback, forcePoll) {
        //Daten nur pollen, falls gerade kein Fenster geöffnet ist
        if (this.state.activeDetailPage === "" || forcePoll) {
            let onSuccess = function (json) {
                //Daten nur aktualisieren, falls kein Fenster geöffnet ist
                if (this.state.activeDetailPage === "" || forcePoll) {
                    let resources = parseJSONListToStories(json.resources);
                    let tasks = parseJSONListToTask(json.events);

                    this.model.getResourceModel().putAll(resources);

                    this.model.putAll(tasks);
                    this.journalid = json.journalid;

                    //Die selektierte Ressource erneuern
                    if(this.state.selectedResource) {
                        for(let r of resources) {
                            if(r.id === this.state.selectedResource.id) {
                                this.setSelectedResource(r);
                            }
                        }
                    }

                    if(resources && resources.length > 0 ) {
                        this.autoAdjustHeight();
                    }
                }
                if (callback) {
                    callback();
                }
            }.bind(this);

            let onError = function (code, json) {
                if (code === 999) {
                    this.sessionIsInvalid();
                } else if (callback) {
                    callback();
                }
            }

            //Daten nur pollen, falls gerade kein Fenster geöffnet ist
            if (this.state.activeDetailPage === "" || forcePoll) {
                TransferHandler.request(Config.getLoadDataURL(), onSuccess, onError, {
                    initial: false,
                    journalid: this.journalid
                });
            } else if (callback) {
                callback();
            }
        }
    }

    cancelStoryEdit() {
        this.setState({cancelEditResourceDialogOpen: true});
    }

    cancelStoryEditNoWarn() {
        if(this.state.selectedResource.id === 0) {
            this.toggleResourceVisibility(this.state.selectedResource.id);
            this.setState({splitPaneDividerPos: getDefaultSplitDividerPos(this.state.orientation, this.state.headerHeight)});
        }
        this.setState({
            activeDetailPage: "",
            detailPageAbortable: true,
            menuOpen: false,
            openMainMenuItem: "",
            storyEditModeID: -1,
            storyEditMode: "",
            savingData: false
        });
    }

    closeBookingDetails(isInEditMode) {
        if(isInEditMode) {
            this.setState({cancelEditTaskDialogOpen: true});
        } else {
            this.closeDetails();
        }
    }

    saveTasks(tasks, choosenOpts, shouldCheck, callback) {
            this.setState({savingData: true});
            let tasksToSave = [];
            let deletedTasks = [];
            for (let t of tasks) {
                let task = {
                    id: t.getID(),
                    resid: t.getResID(),
                    startYear: t.getStart() ? t.getStart().getYear() : null,
                    startMonth: t.getStart() ? t.getStart().getMonth() : null,
                    startDay: t.getStart() ? t.getStart().getDay() : null,
                    startHour: t.getStart() ? t.getStart().getHour() : null,
                    startMinute: t.getStart() ? t.getStart().getMinute() : null,
                    startPrecision: t.getStart() ? t.getStart().getPrecision()
                        : null,
                    startType: t.getStart() ? t.getStart().getType() : null,
                    endYear: t.getEnd() ? t.getEnd().getYear() : null,
                    endMonth: t.getEnd() ? t.getEnd().getMonth() : null,
                    endDay: t.getEnd() ? t.getEnd().getDay() : null,
                    endHour: t.getEnd() ? t.getEnd().getHour() : null,
                    endMinute: t.getEnd() ? t.getEnd().getMinute() : null,
                    endPrecision: t.getEnd() ? t.getEnd().getPrecision() : null,
                    endType: t.getEnd() ? t.getEnd().getType() : null,
                    label: t.getName(),
                    secName: t.getSecName(),
                    description: t.getDescription(),
                    mapDescriptor: t.getMapDescriptor(),
                    imagelicense: t.getImageLicense(),
                    sourcereference: t.getSourceReference(),
                    wikipage: t.getWikiPage(),
                    dataset: t.getDataset(),
                    connections: t.connections,
                    color: Helper.hexColorStringToInt(
                        t.getDisplayData().getColor()),
                    shape: t.getDisplayData().getShape(),
                    position: t.getDisplayData().getPosition(),
                    expansion: t.getDisplayData().getExpansionFactor(),
                    fontSizeFactor: t.getDisplayData().getFontSizeFactor(),
                    transparency: t.getDisplayData().getTransparency(),
                    bold: t.getDisplayData().getBold(),
                    italic: t.getDisplayData().getItalic(),
                    bargroup: t.getDisplayData().getBarGroup(),
                    innerevents: innerEvents2json(t.innerEvents),
                    deleted: t.isDeleted()
                };
                if (t.imgName !== null) {
                    task.imgName = t.imgName;
                    task.imageBase64 = t.imageBase64;
                }
                //Das hier kann nur von Kopien kommen, da die imgSrc ansonsten immer vom Server kommt
                if (t.imgSrc != null) {
                    task.imgSrc = t.imgSrc;
                }
                if (t.isDeleted()) {
                    deletedTasks.push(task);
                }

                tasksToSave.push(task);
            }

            let onSuccess = function (json) {
                //Die gelöschten auch aus dem Model entfernen
                for(let t of tasksToSave) {
                    this.model.removeByID(t.id, false);
                }

                this.pollChanges(() => {
                    this.setState({savingData: false, lastDataSaveTime: Date.now()});
                    this.closeDetails(() => this.setSelectedTask(null));
                    callback && callback();
                    //this.autoAdjustHeight(false, callback);
                }, true);
            }.bind(this);

            let onError = function (code, json) {
                if (code === 999) {
                    this.sessionIsInvalid();
                }
                this.setState({savingData: false});
            }.bind(this);

            this.setState({lastDataSaveTime: Date.now()});
            if(this.decorationdescriptorBeforeEdit === this.getSelectedStory().decorationdescriptor) {
                TransferHandler.request(Config.getSaveURL(), onSuccess, onError, {
                    tasks: tasksToSave
                });
            } else {
                TransferHandler.request(Config.getSaveURL(), onSuccess, onError, {
                    tasks: tasksToSave,
                    decorationdescriptor: this.getSelectedStory().decorationdescriptor,
                    storyId: this.getSelectedStory().id
                });
            }
    }

    saveStory(story) {
        const members = story.getMembers();
        if (!story.isDeleted()) {
            if (story.getName() === null || story.getName().trim().length < 3) {
                this.setState({
                    canPressOKInWarningDialog: false,
                    dialogWarningText: i18n("histomaniamain.js12025145")
                });
            } else if (members && members.length > 0 && members[0].isdummy) {
                this.setState({
                    currentStoryToSave: story,
                    canPressOKInWarningDialog: true,
                    dialogWarningText:
                        <span>{i18n("histomaniamain.js310062970")}<br/>{i18n("histomaniamain.js734544182")}</span>
                });
            } else {
                this.saveStoryNoWarn(story);
            }
        } else {
            this.saveStoryNoWarn(story);
        }
    }

    saveStoryNoWarn(story, callback) {
        if(story.id === 0) {
            this.setState({
                splitPaneDividerPos: getDefaultSplitDividerPos(this.state.orientation, this.state.headerHeight),
                dialogWarningText: false,
                savingData: true
            });
        } else {
            this.setState({dialogWarningText: false, savingData: true});
        }
        //Handelt es sich um die Geschichte einer Dummy-Ressource? -> Warnhinweis
        const members = story.getMembers();

        let collapsedGroups = [];
        for(let collapsedGroupWithRes of this.model.getCollapsedGroups()) {
            const splits = collapsedGroupWithRes.split("@");
            if(splits.length === 2) {
                collapsedGroups.push(splits[1]);
            }
        }

        let resource = {
            id: story.getID(),
            members,
            isPrivate: story.isPrivateB(),
            name: story.getName(),
            secname: story.secname,
            description: story.getDescription(),
            mapDescriptor: story.getMapDescriptor(),
            addstorydescriptor: story.addstorydescriptor,
            decorationdescriptor: story.decorationdescriptor,
            imagelicense: story.getImageLicense(),
            sourcereference: story.getSourceReference(),
            wikipage: story.getWikiPage(),
            deleted: story.isDeleted(),
            collapsedgroups: collapsedGroups,
            language: story.language
        }
        if (story.imgName !== null) {
            resource.imgName = story.imgName;
            resource.imageBase64 = story.imageBase64;
        }

        let onSuccess = (json) => {
            //Speicherung in der DB, dabei wird die ID positiv. Als Response kommt die neue ID
            if (story.getID() === 0) {
                this.model.getResourceModel().remove(story);
                this.setState({
                    lastDataSaveTime: Date.now(),
                    warnDialogType: "info",
                    canPressOKInWarningDialog: false,
                    dialogWarningText: i18n("newHiStoryMessage")()
                });
            }
            this.setState({storyEditModeID: -1, storyEditMode: ""});

            this.pollChanges(() => {
                this.closeDetails();
                callback && callback();
            }, true);
        };

        let onError = (code, json) => {
            if (code === 1) {
                this.setState({
                    savingData: false,
                    snackbarMessage: i18n("histomaniamain.js218992075"),
                    snackbarAutoClose: true
                });
            } else if (code === 999) {
                this.setState({
                    savingData: false});
                this.sessionIsInvalid();
            }
        }

        this.setState({lastDataSaveTime: Date.now()});
        TransferHandler.request(Config.getSaveURL(), onSuccess, onError, {resource: resource});
    }

    initNewResource(res) {
        res.setID(0);
        this.model.getResourceModel().add(res);

        //Ein Eintrag mit dem aktuellen Benutzer wird immer vorbelegt
        //TODO: forename, surname, company aus dem Profil ziehen
        let memberentry = new MemberEntry(histomania_login_user, "", "", "", histomania_login_user_is_dummy, -1, true);

        res.setMembers([memberentry]);
        this.resourceDetails(res, true);
        res.language = localStorage.getItem('histomania_language');
        this.refreshHeaderHeight();
        this.setState({splitPaneDividerPos: window.innerHeight - this.state.headerHeight});
    }

    help() {
        let lang = "de";
        if(localStorage.getItem('histomania_language')) {
            lang = localStorage.getItem('histomania_language');
        }
        window.open('./help/'+lang+'/index.html','_blank');
    }

    copyTaskToResource(task, resID) {
        const newTask = task.clone();
        newTask.id = 0;

        if(task.resID < 0) {
            delete newTask.secname;

            //TODO: Wiki-Bild umwandeln
            newTask.imgSrc = task.imgSrc;
            newTask.imagelicense = task.imagelicense;
            //Wenn nur im secname etwas steht, dann dies in den name kopieren
            //Wenn in name etwas steht, aber in secname auch, dann secname in die Beschreibung kopieren
            if(!task.name || task.name === "") {
                newTask.name = task.secname;
            } else {
                newTask.description = task.secname;
            }
        }
        //ID umwandeln
        newTask.resID = resID;
        newTask.innerEvents = task.innerEvents;

        this.model.put(newTask, false);
        this.saveTasks([newTask], {}, false);
    }

    copyTask(task) {
        const adminRes = this.model.getResourceModel().getAdminResources();
        if(adminRes.length === 1) {
            this.copyTaskToResource(task, adminRes[0].id);
            //TODO: evtl. Hinweis in welche Ressource kopiert wurde
        } else if(adminRes.length > 1) {
            let resChooserCallback = (resID) => {
                this.setState({
                    resChooserDlgOpen: false
                });
                this.copyTaskToResource(task, resID);
            }
            this.setState({resChooserDlgOpen: true, resChooserCallback});
            //TODO: Auswahldialog in welche Ressource kopiert werden soll
        } else {
            this.setState({dialogWarningText: i18n("histomaniamain.js5248846"), canPressOKInWarningDialog: false});
        }
    }

    highlightTaskInTimeline(taskID) {
        const callback = () => {
            const task = this.model.getItemByID(taskID);
            if (task) {
                this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT, this.instrumentedtimeline.goToStartAndHighlight(task));
            }
        }
        if(!isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
            this.setState({splitPaneDividerPos: getDefaultSplitDividerPos(this.state.orientation, this.state.headerHeight)}, callback);
        } else {
            const task = this.model.getItemByID(taskID);
            if(task) {
                this.instrumentedtimeline.goToStartAndHighlight(task);
            }
        }

    }

    mapForTimeInterval(start, end) {
        const filteredItems = this.model.getAll().filter(item => (item.getStart()==null || item.getStart().before(end)) && (item.getEnd()==null || item.getEnd().after(start)))
        var objects = [];
        for(var item of filteredItems) {
            if(item.description) {
                const desc = JSON.parse(item.description);
                const maps = findObjectsWithType(desc, "GoogleDynamicMap");
                for(var map of maps) {
                    if(map.objects) {
                        objects.push(...map.objects);
                    }
                }
            }
        }
        this.showMapDialog({zoom: 5, objects});
    }

    showMapDialog(mapDialogConfig) {
        this.setState({mapDialogConfig: mapDialogConfig});
    }

    taskDetails(task) {
        const detailPage = "bookingdetails";
        this.decorationdescriptorBeforeEdit=this.getSelectedStory().decorationdescriptor;
        //TODO: Falls die Task noch nicht vollständig geladen ist, dann vollständig laden. Ggf. im Model ersetzen
        if (!task.isFullyLoaded && task.id < 0) { //Derzeit noch nicht für positive IDs die Details nachladen. Nie für id = 0!
            this.setSelectedTask(task);
            this.setState({
                activeDetailPage: detailPage,
                detailPageAbortable: true,
                waitOverlayOnDetails: true
            });

            let transferObj = {
                id: task.proxyID || task.id
            }
            let successFunc = (json) => {
                const t = parseJSONTask(json);
                if (t) {
                    //Jetzt hat die Task ggf. eine neue ID, da wenn negativ eine neue ID erzeugt wird (die richtige steht dann in proxyid)
                    t.id = task.id;
                    t.isFullyLoaded = true;

                    task.isFullyLoaded = true;
                    task.refbylinktypes = t.refbylinktypes;
                    task.wdreferences = t.wdreferences;

                    this.setSelectedTask(t);
                    this.setState({
                        activeDetailPage: detailPage,
                        detailPageAbortable: true,
                        waitOverlayOnDetails: false
                    });
                } else {
                    task.isFullyLoaded = true;
                    this.setSelectedTask(task);
                    this.setState({
                        activeDetailPage: detailPage,
                        detailPageAbortable: true,
                        waitOverlayOnDetails: false
                    });
                }
            }
            let errorFunc = (code, json) => {
                if (code === 999) {
                    this.sessionIsInvalid();
                } else {
                    this.setState({
                        waitOverlayOnDetails: false
                    });
                }
            }
            TransferHandler.request(Config.getEventDetailsURL(), successFunc, errorFunc, transferObj);
        } else {
            this.setSelectedTask(task);
            this.setState({
                activeDetailPage: detailPage,
                detailPageAbortable: true,
                waitOverlayOnDetails: false,
            });
        }
    }

    whatIfForTask(id) {
        //Was wäre, wenn sich alle tasks, die zur gleichen Ressource ghören wie diese Task um x Tage verschieben würden?
        const task = this.model.getItemByID(id);
        if (task) {
            this.whatIfForRes(task.getResID(), task.start);
        }
    }

    whatIfForRes(resID, start) {
        if (!(start instanceof LCal)) {
            //Bestimmen des kleinsten Datums für diese Ressource
            const allTasksForRes = this.model.getItemsByResourceID(resID);
            for (let t of allTasksForRes) {
                if (!start || (t.start instanceof LCal && t.start.before(start))) {
                    start = t.start;
                }
            }
        }
        this.setState({whatIfResID: resID, whatIfStart: start});
    }

    moveTasks(resID, days) {
        let res = this.model.getResourceModel().getItemByID(resID);
        res.secname = i18n("histomaniamain.whatif");
        res.whatIf = true;
        let now = new LCal().setJulianMinutes(LCalHelper.getNowMinutes()).setPrecision(10);
        //Was wäre, wenn sich alle Tasks der "resource" um x Tage verschieben würden?
        const allTasksForRes = this.model.getItemsByResourceID(resID);
        for (let t of allTasksForRes) {
            //Falls es einen Start vor dem Jetzt-Zeitpunkt gibt, aber kein Ende, dann wird das Ende auf den Jetzt-Zeitpunkt gesetzt
            if (t.start && !t.end && t.start.before(now)) {
                t.end = now.clone();
            }
            //Falls es ein Ende nach dem Jetzt-Zeitpunkt gibt, aber kein Start, dann wird der Start auf den Jetzt-Zeitpunkt gesetzt
            if (t.end && !t.start && t.end.after(now)) {
                t.start = now.clone();
            }

            t.start && t.start.addDay(days);
            if (t.start !== t.end) {
                t.end && t.end.addDay(days);
            }

            if (t.innerEvents) {
                for (let evt of t.innerEvents) {
                    //Falls es einen Start vor dem Jetzt-Zeitpunkt gibt, aber kein Ende, dann wird das Ende auf den Jetzt-Zeitpunkt gesetzt
                    if (evt.start && !evt.end && evt.start.before(now)) {
                        evt.end = now.clone();
                    }
                    //Falls es ein Ende nach dem Jetzt-Zeitpunkt gibt, aber kein Start, dann wird der Start auf den Jetzt-Zeitpunkt gesetzt
                    if (evt.end && !evt.start && evt.end.after(now)) {
                        evt.start = now.clone();
                    }

                    evt.start && evt.start.addDay(days);
                    evt.end && evt.end.addDay(days);
                }
            }

            //Chartdaten bewegen
            if (t.dataset && t.dataset.length > 0) {
                let dataset = JSON.parse(t.dataset);
                if(dataset.charts) {
                    for (let chart of dataset.charts) {
                        for (let ds of chart.dataset) {
                            const da = ds.date.split(' ');
                            const lcal = new LCal().initYMDHM(da[2] * 1, da[1] * 1, da[0] * 1, da[3] * 1, da[4] * 1);
                            lcal.addDay(days);
                            const newDate = lcal.getDay() + " " + lcal.getMonth() + " " + lcal.getYear() + " " + lcal.getHour() + " " + lcal.getMinute();
                            ds.date = newDate;
                        }
                    }
                    t.dataset = JSON.stringify(dataset);
                }
            }
        }
        this.model._setDisplayDataDirty(true);
        this.model._fireDataChanged();
    }

    resourceDetails(resource, editMode) {
        this.setSelectedResource(resource, () => {
            this.setState({
                storyEditModeID: editMode && resource.id,
                storyEditMode: 'story',
                waitOverlayOnDetails: false
            })
        }, (json) => {
            if (json.code === 999) {
                this.sessionIsInvalid();
            } else {
                this.setState({
                    waitOverlayOnDetails: false
                });
            }
        });
    }

    setSelectedResource(resource, successCB, errCB) {
        let stateCallBack = () => {
            if (resource && resource.id !== 0 && !resource.isFullyLoaded) {
                let transferObj = {
                    id: resource.id
                }
                let successFunc = (json) => {
                    const res = parseJSONStory(json);
                    if (res) {
                        res.isFullyLoaded = true;
                        this.setState({
                            selectedResource: res,
                        }, () => {
                            //Austausch im Model
                            this.model.getResourceModel().put(res, false);
                            successCB && successCB(res);
                            this.refreshTimeline();
                        });

                    } else {
                        resource.isFullyLoaded = true;
                        this.setState({
                            selectedResource: resource
                        }, () => {
                            successCB && successCB(resource);
                            this.refreshTimeline();
                        });
                    }
                }
                let errorFunc = (json) => {
                    if (json.code === 999) {
                        this.sessionIsInvalid();
                    }
                    errCB && errCB(json);
                }
                TransferHandler.request(Config.getEventDetailsURL(), successFunc, errorFunc, transferObj);
            } else {
                successCB && successCB(resource);
                this.refreshTimeline();
            }
        }

        this.setState({
            selectedResource: resource
        }, () => {
            stateCallBack();
        });
    }

    showNotAbortableHint() {
        //TODO
    }

    pricing(show) {
        if(show) {
            this.removeAllVisibleResources();
        }
        this.setState({showPricing: show});
    }

    dataprotection() {
        displayPage(dseContent, Config.getDataprotectionURL(), (data) => dseContent = data, (content) => this.setState({
            dlgOpen: true,
            dlgContent: content
        }));
    }

    agb() {
        displayPage(agbContent, Config.getAGBURL(), (data) => agbContent = data, (content) => this.setState({
            dlgOpen: true,
            dlgContent: content
        }));
    }

    impressum() {
        displayPage(impressumContent, Config.getImpressumURL(), (data) => impressumContent = data, (content) => this.setState({
            dlgOpen: true,
            dlgContent: content
        }));
    }


    sourceReference() {
        this.setState({activeDetailPage: "sourceReference", detailPageAbortable: true});
    }

    cookies() {
        localStorage.removeItem('histomania_consent_googleanalytics');
        localStorage.removeItem('histomania_cookies_accepted');
        localStorage.removeItem('histomania_load_googlemaps');
        localStorage.removeItem('histomania_load_youtube');
        window.location.reload();
    }

    settings() {
        this.setState({activeDetailPage: "settings", detailPageAbortable: false});
    }

    exportData() {
        this.setState({activeDetailPage: "export", detailPageAbortable: true});
    }

    importData() {
        this.setState({activeDetailPage: "import", detailPageAbortable: true});
    }

    embedStories() {
        this.setState({activeDetailPage: "embed", detailPageAbortable: true});
    }

    qrcode() {
      this.setState({activeDetailPage: "qrcode", detailPageAbortable: true});
    }

    editRights() {
        this.setState({activeDetailPage: "editRights", detailPageAbortable: true});
    }

    editProfile() {
        this.setState({activeDetailPage: "editProfile", detailPageAbortable: false});
    }

    setLanguage(language) {
        localStorage.setItem("histomania_language", language);
        this.checkHiStoryLanguages();
    }

    checkHiStoryLanguages() {
        //Prüfen, ob bei den geöffneten hiStories die aktuell gesetzte Sprache gesetzt oder verfügbar ist
        //Falls bereits gesetzt, dann nichts tun
        //Falls die neue Sprache in einer hiStory vorhanden ist, automatisch laden
        //Falls die neue Sprache nicht verfügbar ist, dann Warnmeldung
        //alles konsolidiert anzeigen, also z.B.
        //
        //Die hiStories "Caesar" und "Das Römische Reich" sind aktuell nicht auf englisch verfügbar.
        var language = localStorage.getItem("histomania_language");
        if(!language) {
            language = 'de';
        }
        var hiStoriesForToggle = [];
        var languageNotAvailableRes = [];
        for (let res of this.model.getResourceModel().getAll()) {
            var resLang = res.language || 'de';
            if(resLang !== language) {
                if (res.available_languages && typeof res.available_languages === 'object') {
                    let newResId = res.available_languages[language]; // Den Wert des language-Keys abfragen
                    if (newResId) {
                        if (newResId !== res.id) {
                            hiStoriesForToggle.push(res.id);
                            //Nur pushen, falls noch nicht vorhanden, sonst wird die history ja weggenommen
                            if (this.model.getResourceModel().getItemByID(newResId) !== null) {
                                hiStoriesForToggle.push(newResId);
                            }
                        }
                    } else {
                        languageNotAvailableRes.push(res);
                    }
                } else {
                    languageNotAvailableRes.push(res);
                }
            }
        }
        for(let id of hiStoriesForToggle) {
            this.toggleResourceVisibility(id, null);
        }

        //Anzeige eines Dialgfensters mit allen languageNotAvailableRes
        if (languageNotAvailableRes.length > 0) {
            // Extrahiere die Eigenschaft 'name' aus jedem Objekt und formatiere die Namen
            const formattedNames = languageNotAvailableRes.map(obj => `'${obj.name}'`);

            // Erzeuge die gewünschte Zeichenkette mit "und" vor dem letzten Element
            const formattedText = formattedNames.length > 1
                ? formattedNames.slice(0, -1).join(', ') + ' ' + i18n("and") + ' ' + formattedNames.slice(-1)
                : formattedNames[0];

            // Setze den Zustand mit dem formatierten Text
            this.setState({
                warnDialogType: "info",
                canPressOKInWarningDialog: false,
                dialogWarningText: i18n("langNotAvailable")+ " " + formattedText
            });
        }
    }

    changePassword() {
        this.setState({activeDetailPage: "changePassword", detailPageAbortable: false});
    }

    adminLicenses() {
        this.setState({activeDetailPage: "adminLicenses", detailPageAbortable: false});
    }

    searchStory() {
        this.setState({activeDetailPage: "search", detailPageAbortable: true});
    }

    filterByIds(ids) {
        this.model.setFilteredIDs(new Set(ids));
        this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT);
        //this.zoomToAll(()=>{this.autoAdjustHeight(true, () =>  this.animationCompleted(this.model.getResourceModel().getAll())); this.refreshTimeline()});
    }

    filterOrToggleByIds(ids) {
      let newFilteredIDs = new Set(ids);
      if(this.model.getFilteredIDs() && this.model.getFilteredIDs().equals(newFilteredIDs)) {
        this.model.setFilteredIDs(new Set());
      } else {
        this.model.setFilteredIDs(newFilteredIDs);
      }
      this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT);
    }

    filterTimeline(expression) {
        let filteredIDs = new Set();
        if(expression && expression.length > 0) {
            this.model.getAllBaseData().forEach(item => {
                if (!containsBuzzword(item, expression)) {
                    filteredIDs = filteredIDs.add(item.id)
                }
            });
            this.filterByIds(filteredIDs);
        }
        this.setState({searchPhrase: expression, activeDetailPage: "filtertimeline", detailPageAbortable: true});
    }

    bookmarks() {
        this.setState({activeDetailPage: "bookmarks", detailPageAbortable: true});
    }

    toggleCollapseGroups() {
        if(this.model.getCollapsedGroups().size>0) {
            this.model.clearCollapsedGroups();
        } else {
            this.model.collapseAllGroups();
        }
    }

    printDialog() {
        //Das Model für den Druck erzeugen
        const newModel = this.model.cloneDeep();

        this.setState({printDialogOpen: true, printModel: newModel});
    }

    measure() {
        if(isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)) {
            let measureStartJM = this.instrumentedtimeline.getStartTime().getJulianMinutes();
            let measureEndJM = this.instrumentedtimeline.getEndTime().getJulianMinutes();
            let measureStart = new LCal().setJulianMinutes(
                measureStartJM + (measureEndJM - measureStartJM) / 4);
            let measureEnd = new LCal().setJulianMinutes(
                measureEndJM - (measureEndJM - measureStartJM) / 4);

            //let measureStart = new LCal().initYMDHM(2000, 1, 1, 0, 0, "Europe/Berlin");
            //let measureEnd = new LCal().initYMDHM(2000, 1, 3, 0, 0, "Europe/Berlin");
            this.setState({measureStart: measureStart, measureEnd: measureEnd});
        }
    }

    searchInTime(selectedTask) {
        let now = new LCal().setJulianMinutes(LCalHelper.getNowMinutes()).setPrecision(10);
        let measureStart = selectedTask && selectedTask.start;
        let measureEnd = selectedTask && selectedTask.end && selectedTask.end.getLatestLCal();
        //Falls es einen Start vor dem Jetzt-Zeitpunkt gibt, aber kein Ende, dann wird das Ende auf den Jetzt-Zeitpunkt gesetzt
        if (measureStart && !measureEnd && measureStart.before(now)) {
            measureEnd = now.clone();
        }
        //Falls es ein Ende nach dem Jetzt-Zeitpunkt gibt, aber kein Start, dann wird der Start auf den Jetzt-Zeitpunkt gesetzt
        if (measureEnd && !measureStart && measureEnd.after(now)) {
            measureStart = now.clone();
        }

        this.setState({measureStart, measureEnd}, () => this.searchStory());
    }

    modelToURL() {
        if (window.history.pushState) {
            //Ist nur eine Story geladen und der url-Parameter bekannt, dann kann der Name in die URL gesetzt werden
            let hasBeautifulURL = false;
            let hasResURL = false;
            let baseURL = "https://"+window.location.host+"/";

            if(window.location.href.includes("localhost:3000")) {
                baseURL = "http://localhost:3000/";
            }
            if(this.model.getResourceModel().size()===0) {
                        window.history.pushState(null, null, baseURL);
            } else {
                if (this.model.getResourceModel().size() === 1) {
                    //Positive ID? -> Falls story-URL vorhanden, dann diese als URL nehmen
                    if (this.model.getResourceModel().getItemAt(0).getID() > 0
                        && this.model.getResourceModel().getItemAt(
                            0).getStoryURL() != null
                        && this.model.getResourceModel().getItemAt(
                            0).getStoryURL().length > 0) {

                        const newState =
                            this.model.getResourceModel().getItemAt(
                                0).getStoryURL().replaceAll("/", "_");
                        if (window.history.state !== newState) {
                            window.history.pushState(null, null, baseURL + newState);
                        }
                        hasBeautifulURL = true;
                        hasResURL = true;
                        //Negative ID? Da kann als URL irgendwas da stehen, im letzten Teil wird die ID mitgegeben
                    } else if (this.model.getResourceModel().getItemAt(
                        0).getID() < 0) {
                        const newState =
                            this.model.getResourceModel().getItemAt(
                                0).getName().replaceAll(" ", "_").replaceAll("/", "_") + "_W"
                            + (-this.model.getResourceModel().getItemAt(
                            0).getID());

                        if (window.history.state !== newState) {
                            window.history.pushState(null, null, baseURL + newState);
                        }
                        hasBeautifulURL = true;
                        hasResURL = true;
                    }
                }

                if (!hasBeautifulURL) {
                    const modelStories = this.model.getResourceModel().getAll().map(
                        x => x.id).sort().join(',');
                    let paramStories = getParameterByName("stories");
                    if (paramStories) {
                        paramStories = paramStories.split(",").map(
                            x => +x).sort().join(',');
                    }
                    if (modelStories && modelStories.length > 0) {
                        if(modelStories !== paramStories) {
                            window.history.pushState(null, null,
                                baseURL + "?stories=" + modelStories);
                        }
                        hasResURL = true;
                    }
                }

                if (!hasResURL) {
                    if (window.location.href.includes("?")) {
                        window.history.pushState(null, null,
                            window.location.href.split('?')[0]);
                    }
                }
            }
        }
        this.refreshHeaderHeight();
    }

    urlToModel() {
        const urlStories = getParameterByName("stories");
        if(urlStories) {
            let storyIDs = urlStories.split(",").map(x=>+x); //convert to integer array
            this.setVisibleResources(storyIDs, ()=>this.modelToURL());
        } else {
            this.modelToURL();
        }
    }

    refreshTimeline() {
        if (this.instrumentedtimeline && this.instrumentedtimeline.timelineRef) {
            this.model._setDisplayDataDirty(true);
            this.model.recomputeDisplayData(this.instrumentedtimeline.timelineRef.getTaskBarBounds);
            this.instrumentedtimeline.timelineRef._updateCanvas();
        }
    }
    resourceVisibilityChanged(resID, isvisible) {
        if (isvisible) {
            //Falls ein Eintrag sichtbar gemacht werden soll, dann muss dieser von der DB nachgeladen werden
            this.loadAllData(()=>{this.modelToURL(); this.checkHiStoryLanguages()}, resID);
        } else {
            //Falls unsichtbar gesetzt werden soll, dann verschwindet der Eintrag einfach aus der Tabelle
            let res = this.model.getResourceModel().getItemByID(resID);
            if (res) {
                this.model.removeResource(res);
                this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT, ()=>this.refreshTimeline());
            }
            if(this.state.selectedResource && this.state.selectedResource.id===resID) {
                //Die selektierte Ressource ist dann die erste
                let allRes = this.model.getResourceModel().getAll();
                if(allRes.length > 0) {
                    this.setSelectedResource(allRes[0]);
                } else {
                    this.setSelectedResource(null);
                }
            }
            this.modelToURL();
        }
    }

    setVisibleResources(resIDs, callback) {
        //Welche Ressourcen müssen entfernt werden, welche müssen zugefügt werden?
        const modelIDs = this.model.getResourceModel().getAll().map(x=>x.id);
        const idsForRemoval = modelIDs.filter(x=>!resIDs.includes(x));
        const idsForAdd = resIDs.filter(x=>!modelIDs.includes(x));
        this.removeVisibleResources(idsForRemoval, callback);
        this.addVisibleResources(idsForAdd, callback);
    }

    removeVisibleResources(resIDs, callback) {
        for (let resid of resIDs) {
            let hasRes = this.model.getResourceModel().getItemByID(resid);
            if (hasRes) {
                this.toggleResourceVisibility(resid, callback);
            }
        }
    }

    removeAllVisibleResources(callback) {
        this.model.clearFilteredIDs();
        const modelIDs = this.model.getResourceModel().getAll().map(x=>x.id);
        this.removeVisibleResources(modelIDs, callback);
    }

    addVisibleResources(resIDs, callback) {
        for (let resid of resIDs) {
            let hasRes = this.model.getResourceModel().getItemByID(resid);
            if (!hasRes) {
                this.toggleResourceVisibility(resid, callback);
            }
        }
    }

    toggleResourceVisibility(resid, callback) {
        //Das Ausblenden der Ressourcen geschieht optimistisch
        let hasRes = this.model.getResourceModel().getItemByID(resid);
        let isBlindOut = false;
        if (hasRes) {

            if(this.model.getResourceModel().getAll().length===1) {
                isBlindOut = true;
            }
            this.resourceVisibilityChanged(resid, false);
        }

        let pendingResourceIDs = this.state.pendingResourceIDs.add(resid);
        this.setState({pendingResourceIDs});

        //Holen der (gefilterten) Ressourcen
        let transferObj = {
            resourceid: resid,
            isvisible: !hasRes
        }
        let successFunc = (json) => {
            const change = () => {
                //In der Tabelle den Eintrag bestätigen und die Info nach aussen propagieren
                this.resourceVisibilityChanged(resid, !hasRes);
                let pendingResourceIDs = this.state.pendingResourceIDs.delete(resid);
                this.setState({pendingResourceIDs});
                this.closeDetails();
                callback && callback();
            }
            if(isBlindOut) {
                setTimeout(()=>change(), 800);
            } else {
                change();
            }
        }
        let errorFunc = (code, json) => {
            if (code === 999) {
                this.sessionIsInvalid();
            } else {
                let pendingResourceIDs = this.state.pendingResourceIDs.delete(resid);
                this.setState({pendingResourceIDs});
            }
        }
        TransferHandler.request(Config.getVisibleResourceURL(), successFunc, errorFunc, transferObj);
    }

    refreshResource(resid) {
        let hasRes = this.model.getResourceModel().getItemByID(resid);
        if (hasRes) {
            this.toggleResourceVisibility(resid, ()=> this.toggleResourceVisibility(resid));
        }
    }

    closeDetails(afterCloseCB) {
        this.model.setSelectedItemIDs([]);
        //this.model.setMovedTasks([]);

        this.model.removeByID(0);
        this.model.getResourceModel().removeByID(0);
        if(this.state.selectedResource && this.state.selectedResource.id === 0) {
            //Die selektierte Ressource ist dann die erste
            let allRes = this.model.getResourceModel().getAll();
            if (allRes.length > 0) {
                this.setSelectedResource(allRes[0]);
            } else {
                this.setSelectedResource(null);
            }
        }

        this.setState({
            activeDetailPage: "",
            detailPageAbortable: true,
            menuOpen: false,
            openMainMenuItem: "",
            taskEditMode: false,
            searchPhrase: "",
            searchGeoLon: "",
            searchGeoLat: "",
            searchGeoType: "",
            activeSearchTab: "",
            measureStart: null,
            measureEnd: null,
            savingData: false
        }, ()=>{afterCloseCB && afterCloseCB(); this.refreshTimeline()});

        //Steht was in der Historie, soll ich eine vorangegangene Detailpage öffnen?
        /*const histEntry = this.pageHistory.pop();
        if (histEntry) {
            switch (histEntry.detailPage) {
                case "StoryDetails":
                    this.resourceDetails(histEntry.item);
                    break;
                default:
            }
        }*/

        /*setTimeout(() => {
            afterCloseCB && afterCloseCB();
        }, 0);*/
    }


    pushToHistory(detailPage, item) {
        this.pageHistory.push({detailPage, item});
    }

    detailViewTypeChanged(val) {
        this.setState({detailViewType: val});
    }

    maxLabelLengthChanged(val) {
        this.setState({maxLabelLength: val});
    }

    onOrientationTypeChanged(val) {
        this.setState({orientationType: val, splitPaneDividerPos: getDefaultSplitDividerPos(val, this.state.headerHeight)});
    }

    onShowTimelineBackgroundImageChanged(val) {
        this.setState({showTimelineBackgroundImage: val});
    }

    onTimelineBackgroundDarkChanged(val) {
        this.setState({timelineBackgroundDark: val})
    }

    saveAndCloseSettings() {
        //TODO: speichern nur, falls etwas geändert wurde
        this.closeDetails();

        let transferObj = {
            barSize: this.model.barSize, //TODO: nicht mehr nötig
            orientationType: this.state.orientationType === "horizontal" ? 1 : 0,
            detailViewType: this.state.detailViewType === "drawer" ? 1 : (this.state.detailViewType === "overlay" ? 2 : 0),
            maxLabelLength: this.state.maxLabelLength === "limited" ? 0 : 1,
            showTimelineBackgroundImage: this.state.showTimelineBackgroundImage === "true" ? 1 : 0,
            timelineBackgroundDark: this.state.timelineBackgroundDark === "true" ? 1 : 0
        }

        let successFunc = (json) => {
            console.log("Benutzereinstellungen wurden gespeichert");
        }
        let errorFunc = (code, json) => {
            if (code === 999) {
                this.sessionIsInvalid();
            } else {
                console.log("Fehler beim Speichern der Benutzereinstellungen");
            }
        }

        TransferHandler.request(Config.getSaveSettingsURL(), successFunc, errorFunc, transferObj);
    }

    search(expression, command) {
        if(command==='nearby') {
            this.searchMyNeighbourhood();
        } else if(command==='positiontime') {
            this.setState({
                searchPhrase: expression,
                activeDetailPage: "search",
                searchGeoType: "choosenNeighbourhood",
                detailPageAbortable: true
            });
        } else if(command==='myhistories') {
            this.setState({
                searchPhrase: expression,
                activeDetailPage: "search",
                activeSearchTab: "myStories",
                detailPageAbortable: true
            });
        } else if(command==='recentlySeen') {
            this.setState({
                searchPhrase: expression,
                activeDetailPage: "search",
                activeSearchTab: "recentlySeen",
                detailPageAbortable: true
            });
        } else {
            this.setState({
                searchPhrase: expression,
                activeDetailPage: "search",
                detailPageAbortable: true
            });
        }
    }

    searchNeighbourhood(lon, lat) {
        this.setState({
            searchPhrase: "",
            searchGeoLon: ("" + lon).replace(".", ","),
            searchGeoLat: ("" + lat).replace(".", ","),
            searchGeoType: "choosenNeighbourhood",
            activeDetailPage: "search",
            detailPageAbortable: true
        });
    }

    searchMyNeighbourhood() {
        this.setState({
            searchPhrase: "",
            searchGeoLon: "",
            searchGeoLat: "",
            searchGeoType: "myNeighbourhood",
            activeDetailPage: "search",
            detailPageAbortable: true
        });


        /*navigator.geolocation.getCurrentPosition(function (position) {
            this.searchNeighbourhood(position.coords.longitude, position.coords.latitude);
        }.bind(this), function () {
            alert("Bitte erlaube zunächst in den Einstellungen, dass histomania auf deinen Standort zugreifen darf.");
        });*/
    }

    measureIntervalChanged(interval) {
        if ((interval && (interval.start !== this.state.measureStart || interval.end !== this.state.measureEnd))
            || (!interval && (this.state.measureStart !== null || this.state.measureEnd !== null))) {
            this.setState({
                measureStart: interval !== null ? interval.start : null,
                measureEnd: interval !== null ? interval.end : null
            });
        }
    }

    applyDurationToMeasureSlider(duration, pastFuture) {
        let factor = pastFuture === "past" ? -1 : 1;
        let start, end;
        if (this.state.activeMeasureSliderIsStart) {
            start = this.state.measureStart.clone().addMinutes(duration * factor);
            end = this.state.measureEnd.after(start) ? this.state.measureEnd : start;
        } else {
            end = this.state.measureEnd.clone().addMinutes(duration * factor);
            start = this.state.measureStart.before(end) ? this.state.measureStart : end;
        }

        this.setState({moveMeasureSliderDialogIsVisible: false, measureStart: start, measureEnd: end})
    }

    setMeasureAndZoom(measureStart, measureEnd, measureDurationLock, shouldZoom) {
        this.setState({measureDurationLock, measureStart, measureEnd});
        shouldZoom && measureStart && measureEnd && this.zoomTo(measureStart, measureEnd, null, true);
    }

    deleteStory(selRes) {
        selRes.setDeleted(true);
        this.model.getResourceModel().removeByID(selRes.id);
        this.saveStoryNoWarn(selRes, ()=>window.location.reload(false));
    }

    getSelectedStory() {
        return this.state.selectedResource ? this.model.getResourceModel().getItemByID(this.state.selectedResource.id) : null;
    }

    render() {
        const selRes = this.getSelectedStory();

        if(this.state.hasError) {
            console.log(this.state);
            return <div>Es ist ein Fehler passiert</div>
        }

        if(this.state.showSpecialFunction) {
            return <OnLoadComponent resources={this.state.lastLoadedResources} histomaniamain={this} specialFunction={specialFunction} onClose={()=>{this.setState({showSpecialFunction: false}, ()=>this.zoomToAll())}}/>;
        }

            const isEventDetailsInDrawer = this.state.detailViewType === "drawer"
                || this.state.taskEditMode
                || (this.state.detailViewType === "auto" && (window.innerWidth < 1024 || window.innerHeight < 768))

            let divStyle = {
                position: "relative",
                background: "white"
            };

            let sliderValues = SliderHelper.getSliderValues(this.model.getAll());

            let displRange = getMinStartMaxEnd(this.model);

            const SELF = this;
            const substitutionMenu = this.state.measureStart &&  <MeasureHeader
                    measureStart={this.state.measureStart}
                    measureEnd={this.state.measureEnd}
                    measureDurationLock={this.state.measureDurationLock}
                    currentMeasureInterval={this.state.currentMeasureInterval}
                    onChange={(measureStart, measureEnd, measureDurationLock, shouldZoom)=>SELF.setMeasureAndZoom(measureStart, measureEnd, measureDurationLock, shouldZoom)}
                    languageCode={localStorage.getItem("histomania_language")}
                />


            let splitDividerPos, timelineWidth, footerWidth, footerHeight, timelineHeight, splitPaneHeight;

            let showWelcome = this.model.getResourceModel().size() === 0 && !getParameterByName("stories");

            if (this.state.orientationType === "vertical") {
                splitDividerPos = Math.min(this.state.splitPaneDividerPos, this.state.windowWidth);
                if(isNaN(splitDividerPos)) {
                    splitDividerPos = 0;
                }
                timelineWidth = this.state.windowWidth - splitDividerPos;
                timelineHeight = this.state.windowHeight - this.state.headerHeight;
                splitPaneHeight = this.state.windowHeight - this.state.headerHeight;
                footerWidth = splitDividerPos;
                footerHeight = timelineHeight;
            } else {
                splitDividerPos = Math.min(this.state.splitPaneDividerPos, this.state.windowHeight);
                if(isNaN(splitDividerPos)) {
                    splitDividerPos = 0;
                }
                splitPaneHeight = this.state.windowHeight - this.state.headerHeight;
                timelineHeight = splitPaneHeight - splitDividerPos;
                timelineWidth = this.state.windowWidth;
                footerWidth = this.state.windowWidth;
                footerHeight = splitDividerPos;
            }

            let editMode = this.getSelectedTaskID() === 0 || this.state.taskEditMode;
            let drawerWidth = Math.min(editMode ? 1500 : 630, window.innerWidth);
            if(this.state.orientationType === "vertical") {
                if(this.state.splitPaneDividerPos > 600) {
                    drawerWidth = this.state.splitPaneDividerPos;
                }
            }

            const backgroundImage = this.getBackgroundImage();

            let showBackgroundImage = false;
            if(bgImage != null) {
                if(bgImage.toUpperCase() === 'TRUE') {
                    showBackgroundImage = true;
                } else {
                    showBackgroundImage = false;
                }
            } else {
                showBackgroundImage = this.state.showTimelineBackgroundImage === "true";
            }

            let brightBackground;
            if(backgroundTheme) {
                if(backgroundTheme.toUpperCase() === 'DARK') {
                    brightBackground = false;
                } else {
                    brightBackground = !showBackgroundImage;
                }
            } else {
                brightBackground = !(this.state.timelineBackgroundDark === 'true' || (this.state.showTimelineBackgroundImage === "true" && backgroundImage));
            }


        return (
                <MuiThemeProvider theme={theme}>
                    <div style={divStyle}>
                        {(isEventDetailsInDrawer || this.state.activeDetailPage !== "bookingdetails") &&
                        <Drawer anchor="right"
                                open={this.state.activeDetailPage !== ""}
                                variant={this.state.detailPageAbortable ? "persistent" : "temporary"}
                                SlideProps={{unmountOnExit: true}}
                                PaperProps={{
                                    style: {
                                        background: this.state.taskEditMode ? "rgba(255,255,255,.9)" : "rgba(248,248,255,.9)"
                                    }
                                }}
                        >
                            {(() => {
                                switch (this.state.activeDetailPage) {
                                    case "sourceReference":
                                        return <HistoSuspense>
                                                        <SourceReference onClose={() => this.closeDetails()}
                                                                imageSrcFunction={getImgSrc}
                                                                model={this.model}/>
                                                </HistoSuspense>;
                                    case "settings":
                                        return <HistoSuspense>
                                                    <Settings detailViewType={this.state.detailViewType}
                                                         onDetailViewTypeChange={(val) => this.detailViewTypeChanged(val)}
                                                         maxLabelLength={this.state.maxLabelLength}
                                                         onMaxLabelLengthChange={(val) => this.maxLabelLengthChanged(val)}
                                                         orientationType={this.state.orientationType}
                                                         onOrientationTypeChange={(val) => this.onOrientationTypeChanged(val)}
                                                         showTimelineBackgroundImage={this.state.showTimelineBackgroundImage}
                                                         onShowTimelineBackgroundImageChange={(val) => this.onShowTimelineBackgroundImageChanged(val)}
                                                         timelineBackgroundDark={this.state.timelineBackgroundDark}
                                                         onTimelineBackgroundDarkChange={(val) => this.onTimelineBackgroundDarkChanged(val)}

                                                         onClose={() => this.saveAndCloseSettings()}/>
                                                </HistoSuspense>;
                                    case "export":
                                        return <HistoSuspense><Export onClose={() => this.closeDetails()} onPricingClicked={()=>this.pricing(true)}/></HistoSuspense>;
                                    case "import":
                                        return <HistoSuspense>
                                            <Import onClose={() => this.closeDetails()}
                                                       onFileUploaded={() => {
                                                            this.setState({activeDetailPage: ""});
                                                            this.pollChanges(() => {
                                                                this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT);
                                                                    //Die selektierte Ressource ist dann die erste
                                                                    let allRes = this.model.getResourceModel().getAll();
                                                                    if(allRes.length > 0) {
                                                                        this.setSelectedResource(allRes[0]);
                                                                    } else {
                                                                        this.setSelectedResource(null);
                                                                    }

                                                                this.modelToURL();
                                                            }, true)
                                                        }}
                                                       onSave={(tasks, choosenOpts) => this.saveTasks(tasks, choosenOpts, false, ()=>this.fitToScreen(MIN_BAR_HEIGHT, MAX_BAR_HEIGHT))}
                                                       model={this.model}
                                            />
                                        </HistoSuspense>;
                                    case "embed":
                                        return <HistoSuspense><Embed onClose={() => this.closeDetails()} model={this.model}/></HistoSuspense>;
                                    case "qrcode":
                                      return <HistoSuspense><QRCode onClose={() => this.closeDetails()} model={this.model}/></HistoSuspense>;
                                    case "login":
                                        return <Login onClose={() => {
                                            this.closeDetails();
                                            this.loginWithTempAccount();
                                        }}
                                                      onLogIn={this.loggedIn}
                                                      initialCard={this.state.initialLoginCard}/>;
                                    case "bookingdetails":
                                        return this.getSelectedTask() && <HistoSuspense>
                                                                                <BookingDetails
                                                                                         onClose={() => this.closeBookingDetails(this.getSelectedTaskID() === 0 || this.state.taskEditMode)}
                                                                                         onEdit={(edit) => this.setState({
                                                                                             detailPageAbortable: !edit,
                                                                                             taskEditMode: edit
                                                                                         })}
                                                                                         onCopy={() => this.copyTask(this.getSelectedTask())}
                                                                                         onFilter={()=>this.filterTimeline()}
                                                                                         lastDataSaveTime={this.state.lastDataSaveTime}
                                                                                         editMode={editMode}
                                                                                         savingData={this.state.savingData}
                                                                                         onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                                                                         onHighlight={(taskid) => this.highlightTaskInTimeline(taskid)}
                                                                                         onFilterClicked={(item, model) => this.filterOrToggleByIds(item.filteredIDs)}
                                                                                         onShowMap={(lat, lng) => this.showMapDialog({objects: [{type:"marker", latitude: lat, longitude: lng}]})}
                                                                                         onSearch={(expr) => this.search(expr)}
                                                                                         onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhood(lon, lat)}
                                                                                         onResShowDetails={(id) => this.resourceDetails({id})}
                                                                                         onWhatIf={() => this.whatIfForTask(this.getSelectedTaskID())}
                                                                                         onWhatIfReverse={(resID) => this.refreshResource(resID)}
                                                                                         onInThisTime={() => this.searchInTime(this.getSelectedTask())}
                                                                                         onSave={(tasks, choosenOpts) => this.saveTasks(tasks, choosenOpts, true)}
                                                                                         data={this.getSelectedTask()}
                                                                                         model={this.model}
                                                                                         pendingResourceIDs={this.state.pendingResourceIDs}
                                                                                         showWaitOverlay={this.state.waitOverlayOnDetails}
                                                                                         width={drawerWidth}
                                                                                         decorationdescriptor={this.getSelectedStory().decorationdescriptor}
                                                                                         onDecorationdescriptorChanged={(decorationdescriptor)=>{
                                                                                             this.getSelectedStory().decorationdescriptor = JSON.stringify(decorationdescriptor);
                                                                                             this.forceUpdate();
                                                                                         }
                                                                                        }
                                                                                />
                                                                    </HistoSuspense>;
                                    case "editProfile":
                                        return <HistoSuspense>
                                                    <EditProfile onClose={() => this.closeDetails()}/>
                                                </HistoSuspense>
                                    case "changePassword":
                                        return <HistoSuspense>
                                                    <ChangePassword onClose={() => this.closeDetails()}/>
                                                </HistoSuspense>;
                                    case "adminLicenses":
                                        return <HistoSuspense>
                                            <AdminLicenses onClose={() => this.closeDetails()}/>
                                        </HistoSuspense>;
                                    case "search":
                                        return <HistoSuspense>
                                                    <Search onClose={() => this.closeDetails()}
                                                           onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                                           onHighlight={(taskid) => this.highlightTaskInTimeline(taskid)}
                                                           onShowMap={(lat, lng) => this.showMapDialog({objects: [{type:"marker", latitude: lat, longitude: lng}]})}
                                                           onResShowDetails={(id) => this.resourceDetails({id})}
                                                           onTaskShowDetails={(task) => this.taskDetails(task)}
                                                           searchPhrase={this.state.searchPhrase}
                                                           searchFrom={this.state.measureStart}
                                                           searchTo={this.state.measureEnd}
                                                           searchGeoLon={this.state.searchGeoLon}
                                                           searchGeoLat={this.state.searchGeoLat}
                                                           searchGeoType={this.state.searchGeoType}
                                                           model={this.model}
                                                           pendingResourceIDs={this.state.pendingResourceIDs}
                                                           onSearch={(expr) => this.search(expr)}
                                                           showWaitOverlay={this.state.waitOverlayOnDetails}
                                                           activeTab = {this.state.activeSearchTab}
                                                    />
                                                </HistoSuspense>;
                                    case "filtertimeline":
                                        return <HistoSuspense>
                                                    <FilterTimeline onClose={() => this.closeDetails()}
                                                               model={this.model}
                                                               initialFilterStartTime={this.state.measureStart}
                                                               initialFilterEndTime={this.state.measureEnd}
                                                               onTaskShowDetails={(task) => this.highlightTaskInTimeline(task.id)}
                                                               searchPhrase={this.state.searchPhrase}
                                                               onFilterIds={(filteredIDs) => this.filterByIds(filteredIDs)}
                                                    />
                                                </HistoSuspense>
                                    case "bookmarks":
                                        return <HistoSuspense>
                                                    <Bookmarks onClose={() => this.closeDetails()}
                                                          onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                                          onHighlight={(taskid) => this.highlightTaskInTimeline(taskid)}
                                                          onFilterClicked={(item, model) => this.filterByIds(item.filteredIDs)}
                                                          onShowMap={(lat, lng) => this.showMapDialog({objects: [{type:"marker", latitude: lat, longitude: lng}]})}
                                                          onResShowDetails={(id) => this.resourceDetails({id})}
                                                          model={this.model}
                                                          pendingResourceIDs={this.state.pendingResourceIDs}
                                                          showWaitOverlay={this.state.waitOverlayOnDetails}
                                                          onBookmarkSelected={(storyIDs) => {
                                                              this.closeDetails();
                                                              this.addVisibleResources(storyIDs)
                                                          }}
                                                    />;
                                                </HistoSuspense>
                                    default:
                                        return <HistoSuspense><Details/></HistoSuspense>;
                                }
                            })()}
                        </Drawer>
                        }

                        <div>
                            {!noheader && <div style={{position: "relative"}}>
                                {this.state.headerHeight > 0 && <Header model={this.model}
                                                                        height={this.state.headerHeight}
                                                                        onSearch={(expression, command) => this.search(expression, command)}
                                                                        onFilter={(expression, command) => this.filterTimeline(expression)}
                                                                        onPrint={() => this.printDialog()}
                                                                        onSettings={()=>this.settings()}
                                                                        onBookmark={() => this.bookmarks()}
                                                                        onClose={()=> this.removeAllVisibleResources()}
                                                                        onMeasure={() => this.measure()}
                                                                        onMore={(event) => this.setState({
                                                                            menuOpen: true,
                                                                            menuAnchorEl: event.currentTarget
                                                                        })}
                                                                        onLanguage={(event) => this.setState({
                                                                            languageMenuOpen: true,
                                                                            languageMenuAnchorEl: event.currentTarget
                                                                        })}
                                                                        onLogin={() => this.login()}
                                                                        loginUser={histomania_login_user}
                                                                        loginUserIsDummy={histomania_login_user_is_dummy}
                                                                        userLoggedIn={this.state.userLoggedIn}
                                                                        timelinevisible={isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight)}
                                                                        selectedResource={selRes}
                                                                        backgroundImage={backgroundImage}
                                                                        onHighlight={(taskid) => this.highlightTaskInTimeline(taskid)}
                                                                        onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                                                        onGoToResource={resID => {
                                                                            const res = this.model.getResourceModel().getItemByID(
                                                                                resID);
                                                                            if (res) {
                                                                                this.setState(
                                                                                    {selectedResource: res});
                                                                                this.setSelectedResource(
                                                                                    res);
                                                                                if (isTimetableVisible(
                                                                                    this.state.orientationType,
                                                                                    this.state.splitPaneDividerPos,
                                                                                    this.state.headerHeight)) {
                                                                                    this.instrumentedtimeline.goToResource(
                                                                                        res);
                                                                                }
                                                                            }
                                                                        }}
                                                                        substitutionMenu={substitutionMenu}
                                />}
                            </div>
                            }

                            <div style={{position: "relative", height: splitPaneHeight}}>
                                {this.state.firstDataLoaded ? (showWelcome ? <Welcome key={"welcome"+localStorage.getItem("histomania_language")} onSearch={() => this.setState({activeDetailPage: "search"})}
                                                                                                                        onShowPricing={(show)=>this.pricing(show)}
                                                                                                                        showPricing={this.state.showPricing}
                                                                                                                       onNew={() => this.initNewResource(new Story())}
                                                                                                                       onHelp={() => this.help()}
                                                                                                                       onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                                                                                                       onSearchMyNeighbourhood={()=>this.searchMyNeighbourhood()}
                                                                                                                       model={this.model}
                                                                                                                       pendingResourceIDs={this.state.pendingResourceIDs}
                                                                                                                       onResShowDetails={(id) => this.resourceDetails({id})}
                                                                                                                        onJumpToLicenseAdmin={()=>this.adminLicenses()}
                                                                                                                       open={true}
                                                                                                                       height={window.innerHeight - this.state.headerHeight}
                                                                                                                       onLogin={() => this.login()}
                                                                                                                       loginUser={histomania_login_user}
                                                                                                                       loginUserIsDummy={histomania_login_user_is_dummy}
                                                                                                                       userLoggedIn={this.state.userLoggedIn}
                                                                                                                        onMyStories={()=>this.setState({
                                                                                                                            searchPhrase: "",
                                                                                                                            activeDetailPage: "search",
                                                                                                                            activeSearchTab: "myStories",
                                                                                                                            detailPageAbortable: true
                                                                                                                        })}
                                                                                                                          onRecentlySeen={()=>this.setState({
                                                                                                                              searchPhrase: "",
                                                                                                                              activeDetailPage: "search",
                                                                                                                              activeSearchTab: "recentlySeen",
                                                                                                                              detailPageAbortable: true
                                                                                                                          })}
                                                                                                                        languageCode={localStorage.getItem("histomania_language")}
                                    />
                                    :
                                    <SplitPane split={this.state.orientationType}
                                               minSize={0}
                                               maxSize={this.state.orientationType === "vertical" ? window.innerWidth : window.innerHeight - this.state.headerHeight}
                                               size={splitDividerPos}
                                               style={{height: splitPaneHeight}}
                                               onDragFinished={(newSize) => !isNaN(newSize) && this.setState({splitPaneDividerPos: newSize})}
                                               primary={"second"}
                                    >

                                            <div key="timeline" style={{position: "absolute", boxShadow: "inset 0px 0px 13px 8px rgba(0,0,0,0.47)"}} ref={this.timelineDIV}>
                                                <MediaFullScreenWrapper style={{overflow: 'hidden'}} hideFullScreenButton={timelineHeight === window.innerHeight && timelineWidth === window.innerWidth} zIndex={1150} childs={fullScreen =>
                                                    <>
                                                        {fullScreen && this.state.measureStart && <MeasureHeader
                                                            measureStart={this.state.measureStart}
                                                            measureEnd={this.state.measureEnd}
                                                            measureDurationLock={this.state.measureDurationLock}
                                                            currentMeasureInterval={this.state.currentMeasureInterval}
                                                            onChange={(measureStart, measureEnd, measureDurationLock, shouldZoom)=>SELF.setMeasureAndZoom(measureStart, measureEnd, measureDurationLock, shouldZoom)}
                                                            languageCode={localStorage.getItem("histomania_language")}
                                                        />}
                                                        <InstrumentedTimeline
                                                            horizontalAdditionalControl={<HtmlTooltip arrow title={<Typography>{i18n("histomaniamain.js114097156")}</Typography>}><SettingsOverscanTwoToneIcon fontSize="large" style={{color: theme.palette.secondary.main, position: "absolute", left: -40, bottom: -8}} onClick={() => this.fitToScreen()}/></HtmlTooltip>}
                                                            config={timelineConfig}
                                                            width={fullScreen? window.innerWidth: timelineWidth}
                                                            height={fullScreen ? (this.state.measureStart ? window.innerHeight - 80: window.innerHeight): timelineHeight}
                                                            headerType={'inline'}
                                                            showWaitOverlay={this.state.loadingData}
                                                            waitOverlay={() => <div style={waitStyle(timelineWidth, timelineHeight + this.state.headerHeight)}><CircularProgress size={100} style={{color: "black"}}/></div>}
                                                            model={this.model}
                                                            start={displRange.minStart}
                                                            end={displRange.maxEnd}
                                                            timeZone={"Europe/Berlin"}
                                                            onClick={(evt) => this.onTimelineClick(evt)}
                                                            onPress={(evt) => this.onTimelinePress(evt)}
                                                            onLongPress={(evt) => this.onTimelineLongPress(evt)}
                                                            onNowDialogClose={() => this.saveAndCloseSettings()}
                                                            sliderValues={sliderValues}
                                                            yearPositions={12}
                                                            initialMeasureInterval={this.state.measureStart ? new LCalInterval(this.state.measureStart, this.state.measureEnd) : null}
                                                            measureDurationLock={this.state.measureDurationLock}
                                                            onMeasureIntervalChanged={(interval, isAligning) => isAligning ? this.setState({currentMeasureInterval: interval}) : this.measureIntervalChanged(interval)}
                                                            backgroundImage={showBackgroundImage && backgroundImage}
                                                            ref={ref => this.instrumentedtimeline = ref}
                                                            paintShadows={true}
                                                            highlightArrow={<Arrow/>}
                                                            shortLabels={this.state.maxLabelLength === "limited"}
                                                            overlayheader={true}
                                                            texts={{
                                                                presshere: i18n("histomaniamain.pressHere")
                                                            }}
                                                            backgroundClassName={"backgroundVisibleBlur"}
                                                            canvasClassName={"canvasVisible"}
                                                            onNowButtonLongPress={() => {
                                                                this.setState({jumpToDatePopupOpen: true})
                                                            }}
                                                            brightBackground={brightBackground}
                                                            languageCode={localStorage.getItem("histomania_language")}
                                                        >
                                                        </InstrumentedTimeline>
                                                    </>
                                                }/>
                                            </div>
                                            {selRes && this.state.storyEditMode==='eventtable' && this.state.storyEditModeID>=0 && this.state.storyEditModeID === selRes.id ?
                                                <MaintenanceEventList
                                                    data={selRes}
                                                    model={this.model}
                                                    onSaveEvents={(items)=>this.saveTasks(items, null, true)}
                                                    onDeleteEvent={(item)=>{item.deleted=true; this.saveTasks([item], null, true)}}
                                                    onEventDetails={(task) => this.taskDetails(task)}
                                                    onClose={() => this.setState({
                                                        storyEditModeID: -1,
                                                        storyEditMode: ""
                                                    })}
                                                    showWaitOverlay={this.state.waitOverlayOnDetails}
                                                    height={footerHeight}
                                                    width={footerWidth}
                                                    innerWidth={Math.min(footerWidth - 20, 1400)}
                                                />
                                                :
                                                <StoryDetailsMain
                                                                savingData={this.state.savingData}
                                                                lastDataSaveTime={this.state.lastDataSaveTime}
                                                                onCreateBookingItem={(resID) => {
                                                                    this.createBookingItem(resID)
                                                                }}
                                                                onCreateBookingItemAndSave={(resID) => {
                                                                    const item = this.newBookingItem(resID, false)
                                                                    this.saveTasks([item], null, true);
                                                                    return item;
                                                                }}
                                                                onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                                                onHighlight={(taskid) => this.highlightTaskInTimeline(taskid)}
                                                                onFilterClicked={(item, model) => this.filterOrToggleByIds(item.filteredIDs)}
                                                                onShowMap={(lat, lng) => this.showMapDialog({objects: [{type:"marker", latitude: lat, longitude: lng}]})}
                                                                onSearch={(expr) => this.search(expr)}
                                                                onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhood(lon, lat)}
                                                                onGoToResource={resID => {
                                                                        const res = this.model.getResourceModel().getItemByID(
                                                                            resID);
                                                                        if (res) {
                                                                            this.setState(
                                                                                {selectedResource: res});
                                                                            this.setSelectedResource(
                                                                                res);
                                                                            if (isTimetableVisible(
                                                                                this.state.orientationType,

                                                                                this.state.splitPaneDividerPos,
                                                                                this.state.headerHeight)) {
                                                                                this.instrumentedtimeline.goToResource(
                                                                                    res);
                                                                            }
                                                                        }
                                                                }}
                                                                onTaskShowDetails={(task) => this.taskDetails(task)}
                                                                onWhatIf={() => this.whatIfForRes(selRes.id)}
                                                                onWhatIfReverse={(resID) => this.refreshResource(resID)}
                                                                onSave={(story) => this.saveStory(story)}
                                                                onClose={() => this.cancelStoryEdit()}
                                                                onShowSourceReferences={()=>this.sourceReference()}
                                                                data={selRes}
                                                                model={this.model}
                                                                pendingResourceIDs={this.state.pendingResourceIDs}
                                                                showWaitOverlay={this.state.waitOverlayOnDetails}
                                                                height={footerHeight}
                                                                width={footerWidth}
                                                                innerWidth={Math.min(footerWidth - 20, 1400)}
                                                                onFilter={()=>this.filterTimeline()}
                                                                editMode={selRes && this.state.storyEditMode==='story' && this.state.storyEditModeID>=0 && this.state.storyEditModeID === selRes.id}
                                                                showAlwaysSave={selRes && this.state.storyEditModeID>=0 && this.state.storyEditModeID === selRes.id}
                                                                onEventDetails={(task) => this.taskDetails(task)}
                                                                onDelete={selRes && this.state.storyEditMode==='story' && this.state.storyEditModeID>=0 && this.state.storyEditModeID === selRes.id && (()=>this.deleteStory(selRes))}
                                                                onLogonClicked={()=>this.login()}
                                                                onPricing={(show)=>this.pricing(show)}
                                                            />
                                            }
                                    </SplitPane>)
                                    :
                                    <div style={{
                                        flex: 1,
                                        overflow: "auto",
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: "column",
                                        height: window.innerHeight - this.state.headerHeight
                                    }}>
                                        <CircularProgress size={100} style={{color: "black"}}/>
                                    </div>
                                }
                                {!nofab && (this.state.headerHeight > 0 || this.state.splitPaneDividerPos > 0) && !showWelcome &&  <MainFab onEdit={() => this.setState({
                                            detailPageAbortable: false,
                                            storyEditModeID: selRes.id,
                                            storyEditMode: 'story'
                                         })}
                                         onEventList={() => this.setState({
                                             detailPageAbortable: false,
                                             storyEditModeID: selRes.id,
                                             storyEditMode: 'eventtable'
                                         })}
                                         onNewStory={() => this.initNewResource(new Story())}
                                         onNewTask={() => this.createBookingItemFromScratch()}
                                         isAdmin={selRes && selRes.isAdmin}
                                />}

                            </div>


                            {ListMenu(this.state,
                                (state) => this.setState(state),
                                this.model,
                                this.initNewResource,
                                this.measure,
                                selRes && !selRes.whatIf && (() => this.whatIfForRes(selRes.getID())),
                                selRes && selRes.whatIf && (() => this.refreshResource(selRes.getID())),
                                this.bookmarks,
                                this.filterTimeline,
                                this.printDialog,
                                this.exportData,
                                this.importData,
                                this.embedStories,
                                this.qrcode,
                                this.editProfile,
                                this.setLanguage,
                                this.changePassword,
                                this.adminLicenses,
                                this.settings,
                                this.help,
                                this.dataprotection,
                                this.agb,
                                this.impressum,
                                this.sourceReference,
                                this.cookies,
                                this.login,
                                this.createBookingItemFromScratch,
                                this.openShare,
                                this.mapForTimeInterval,
                                this.props.classes
                            )}

                            {LanguageMenu(this.state.languageMenuAnchorEl,
                                this.state.languageMenuOpen,
                                (state) => this.setState({languageMenuOpen: false}),
                                localStorage.getItem("histomania_language"),
                                this.setLanguage
                            )}

                            {
                                circularMenu(this.state,
                                    (state) => this.setState(state),
                                    this.searchStory,
                                    this.measure,
                                    this.settings,
                                    this.bookmarks,
                                    this.toggleCollapseGroups,
                                    this.initNewResource,
                                    this.createBookingItemFromClick)
                            }


                        </div>


                        {!isEventDetailsInDrawer && <Draggable
                            cancel="strong"
                        >
                            {this.getSelectedTaskID() !== 0 ? <Paper elevation={17}
                                                                      square={false}
                                                                      style={{
                                                                          position: "absolute",
                                                                          background: "rgba(248,248,255,.9)",
                                                                          top: 20,
                                                                          right: 250,
                                                                          cursor: "pointer",
                                                                          height: this.getSelectedTaskID() !== 0 ? 700 : "auto",
                                                                          zIndex: 2000,
                                                                          overflow: "hidden"
                                                                      }}>
                                    <HistoSuspense>
                                        <BookingDetails
                                            onClose={() => this.closeDetails()}
                                            onEdit={(edit) => this.setState({
                                                detailPageAbortable: !edit,
                                                taskEditMode: edit
                                            })}
                                            onCopy={() => this.copyTask(this.getSelectedTask())}
                                            editMode={this.getSelectedTaskID() === 0 || this.state.taskEditMode}
                                            onToggleResourceVisibility={(resid) => this.toggleResourceVisibility(resid)}
                                            onHighlight={(taskid) => this.highlightTaskInTimeline(taskid)}
                                            onShowMap={(lat, lng) => this.showMapDialog({objects: [{type:"marker", latitude: lat, longitude: lng}]})}
                                            onSearch={(expr) => this.search(expr)}
                                            onResShowNeighbourhood={(lon, lat) => this.searchNeighbourhood(lon, lat)}
                                            onResShowDetails={(id) => this.resourceDetails({id})}
                                            onWhatIf={() => this.whatIfForTask(this.getSelectedTaskID())}
                                            onWhatIfReverse={(resID) => this.refreshResource(resID)}
                                            onInThisTime={() => this.searchInTime(this.getSelectedTask())}
                                            onSave={(tasks, choosenOpts) => this.saveTasks(tasks, choosenOpts, true)}
                                            data={this.getSelectedTask()}
                                            model={this.model}
                                            pendingResourceIDs={this.state.pendingResourceIDs}
                                            showWaitOverlay={this.state.waitOverlayOnDetails}
                                            naked={true}
                                            width={550}
                                        />
                                    </HistoSuspense>
                                </Paper>
                                :
                                <div/>
                            }
                        </Draggable>
                        }
                    </div>


                    <Snackbar
                        open={this.state.snackbarMessage && this.state.snackbarMessage !== "" ? true : false}
                        message={this.state.snackbarMessage}
                        autoHideDuration={this.state.snackbarAutoClose ? 2500 : null}
                        onClose={() => this.setState({snackbarMessage: "", snackbarAutoClose: true})}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => this.setState({snackbarMessage: "", snackbarAutoClose: false})}
                            >
                                <CloseIcon/>
                            </IconButton>,
                        ]}
                    />

                    <CancelEditDialog
                        open={this.state.cancelEditResourceDialogOpen}
                        noCallback={() => {
                            this.setState({cancelEditResourceDialogOpen: false});
                            this.cancelStoryEditNoWarn();
                        }}
                        yesCallback={() => {
                            this.setState({cancelEditResourceDialogOpen: false});
                        }}
                    />

                    <CancelEditDialog
                        open={this.state.cancelEditTaskDialogOpen}
                        noCallback={() => {
                            this.setState({cancelEditTaskDialogOpen: false});
                            //Den alten Zustand des decorationdescriptors wiederherstellen
                            this.getSelectedStory().decorationdescriptor = this.decorationdescriptorBeforeEdit;
                            this.closeDetails(()=>this.forceUpdate());
                        }}
                        yesCallback={() => {
                            this.setState({cancelEditTaskDialogOpen: false});
                        }}
                    />

                    <HintDialog
                        dialogWarningText={this.state.dialogWarningText}
                        warnDialogType={this.state.warnDialogType}
                        canPressOKInWarningDialog={this.state.canPressOKInWarningDialog}
                        saveSelectedResourceNoWarn={()=>this.saveStoryNoWarn(this.state.currentStoryToSave)}
                        onClose={() => this.setState({
                            dialogWarningText: null,
                            warnDialogType: "warning",
                        })}
                    />



                    {this.state.dlgOpen && <HistoSuspense>
                                            <ReadmeDialog open={true}
                                                  onClose={() => this.setState({dlgOpen: false})}
                                                  content={this.state.dlgContent}
                                            />
                                        </HistoSuspense>}
                    {this.state.resChooserDlgOpen && <HistoSuspense>
                                                        <ResourceChooserDialog
                                                            open={true}
                                                            onClose={() => this.setState(
                                                                {resChooserDlgOpen: false})}
                                                            title={i18n("histomaniamain.chooseStoryForNewEvent")}
                                                            model={this.model}
                                                            resources={this.model.getResourceModel().getAdminResources()}
                                                            onResourceClick={(resID) => {
                                                                this.state.resChooserCallback
                                                                && this.state.resChooserCallback(resID)
                                                            }}
                                                        />
                                                    </HistoSuspense>
                    }
                    {this.state.whatIfResID !== 0 && <HistoSuspense>
                                                        <WhatIfDialog
                                                            open={true}
                                                            onClose={() => this.setState({whatIfResID: 0})}
                                                            initialStart={this.state.whatIfStart}
                                                            onOK={(days) => {
                                                                this.moveTasks(this.state.whatIfResID, days);
                                                                this.setState({whatIfResID: 0});
                                                                this.closeDetails();
                                                            }}
                                                        />
                                                    </HistoSuspense>
                    }

                    <Popover
                        open={this.state.jumpToDatePopupOpen}
                        onClose={() => {
                            this.setState({jumpToDatePopupOpen: false});
                        }}
                        anchorEl={this.timelineDIV && this.timelineDIV.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div style={{padding: 10}}>
                            <LCalDateField label={i18n("histomaniamain.js513585760")} value={this.state.jumpLCal}
                                           onChange={(d) => this.setState({jumpLCal: d})}
                                           onInputComplete={()=>{isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight) && this.instrumentedtimeline.goToDate(this.state.jumpLCal); this.setState({jumpToDatePopupOpen: false, jumpLCal: null})}}
                                           autoFocus={true}
                                           textFieldStyle={{
                                width: 170,
                                marginLeft: 20
                            }}
                                           yearPositions={10}/>
                            <br/>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <OKButton onClick={()=>{isTimetableVisible(this.state.orientationType, this.state.splitPaneDividerPos, this.state.headerHeight) && this.instrumentedtimeline.goToDate(this.state.jumpLCal); this.setState({jumpToDatePopupOpen: false, jumpLCal: null})}}/>
                            </div>
                        </div>

                    </Popover>
                    {this.state.moveMeasureSliderDialogIsVisible && <HistoSuspense>
                        <MoveMeasureSliderDialog
                            open={true}
                            onClose={() => this.setState({moveMeasureSliderDialogIsVisible: false})}
                            initialLCal={this.state.moveMeasureSliderDialogTime}
                            pastFuture={this.state.activeMeasureSliderPastFuture}
                            onOK={(duration, pastFuture) => this.applyDurationToMeasureSlider(duration, pastFuture)}
                        />
                    </HistoSuspense>}
                    {this.state.printDialogOpen && <PrintLoader open={true}
                                               onClose={() => this.setState(
                                                   {printDialogOpen: false})}
                                               model={this.state.printModel}
                    />
                    }
                    {!!this.state.mapDialogConfig && <HistoSuspense>
                                                        <MapDialog open={true}
                                                                config={this.state.mapDialogConfig}
                                                                onClose={() => this.setState({mapDialogConfig: null})}
                                                        />
                                                    </HistoSuspense>
                    }
                    {!window.isSchoolDomain && this.state.showCookieConsent && <CookieConsent onClose={()=>this.setState({showCookieConsent: false})}/>}
                </MuiThemeProvider>
            )

    }
}

export default withMobileDialog()(withStyles(styles)(HistomaniaMain));
