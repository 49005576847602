import React from 'react';
import linkStyles from '../theme.module.css';
import Typography from "@material-ui/core/Typography";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PostAddIcon from "@material-ui/icons/PostAdd";
import FilterIcon from "../../../../images/icons/filter";
import HtmlTooltip from "../../../../styling/htmltooltip";
import types from "../../../types";
import i18n from "../../../../i18n/i18n";

const link = (onClick, clickObject, children, Tooltip, Icon, style) => (
  <HtmlTooltip arrow title={Tooltip}>
    <span onClick={(e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick && onClick(clickObject);
  }} className={"scheduleSpan"}
          style={style}
    >
      {children}
      {Icon && <span style={{display: "inline-flex"}}><Icon className={"markdownIcon"}/></span>}
      </span>
  </HtmlTooltip>
)

const browserLink = (onClick, clickObject, children) => {
  return link(onClick, clickObject, children, <Typography color="inherit">Link auf externe Website</Typography>);
}

const eventLink = (onClick, clickObject, children) => {
  return link(onClick, clickObject, children, <>
    <Typography color="inherit">{i18n("markdownhelper.js133555219")}</Typography>
    <hr/>
          {i18n("markdownhelper.js499018470")}
  </>,
  ScheduleIcon);
}

const storyLink = (onClick, clickObject, children) => {
  return link(onClick, clickObject, children, <>
        <Typography color="inherit">{i18n("Link.js291014714")}</Typography>
        <hr/>
        {i18n("Link.js501324063")}
      </>,
      PostAddIcon);
}

const filterLink = (onClick, clickObject, children) => {
  let style = {border: "1px solid gray", paddingLeft: 5, paddingRight: 5, paddingTop: 1, paddingBottom: 1, marginBottom: 1, marginTop: 1, borderRadius: 5};
  return link(onClick, clickObject, children, <>
        <Typography color="inherit">{i18n("Link.js62298905")}</Typography>
      </>,
      FilterIcon, style);
}

const Link = (onLinkClick) => ({children, entityKey, getEditorState}) => {
  const entity = getEditorState().getCurrentContent().getEntity(entityKey);
  const entityData = entity && entity.getData();
  const clickObject = (entityData && entityData.clickObject);
  const linkType = (entityData && entityData.linkType);

  return (
      <span
          className={linkStyles.link}
      >
        {
          linkType === types.LINKTYPE_BROWSER && browserLink(onLinkClick, clickObject, children)
        }
        {
          linkType === types.LINKTYPE_EVENT && eventLink(onLinkClick, clickObject, children)
        }
        {
          linkType === types.LINKTYPE_STORY && storyLink(onLinkClick, clickObject, children)
        }
        {
          linkType === types.LINKTYPE_FILTER && filterLink(onLinkClick, clickObject, children)
        }

    </span>
  );
};

export default Link;
