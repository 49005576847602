import React from "react";
import TitleList from "../search/lists/titlelist";
import i18n from "../i18n/i18n";
import Arrow from "./arrow";

/**
 * Der Lehrplan
 */
const Curriculum = ({onToggleResourceVisibility, languageCode}) => {
        return <div style={{marginTop: 40, display: 'grid', gridTemplateColumns: '60px 20px auto' }}>
            <Arrow col1={"#ee0000"} col2={"#ee7777"} name={i18n("schoolclass6")}/>
            <div/>
            <TitleList id="schoolclass6" url={'schoolclass.php?number=6&lang='+languageCode}
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <Arrow col1={"#555555"} col2={"#aaaaaa"} name={i18n("schoolclass7")}/>
            <div/>
            <TitleList id="schoolclass7" url={'schoolclass.php?number=7&lang='+languageCode}
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <Arrow col1={"#ee0000"} col2={"#ee7777"} name={i18n("schoolclass8")}/>
            <div/>
            <TitleList id="schoolclass8" url={'schoolclass.php?number=8&lang='+languageCode}
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <Arrow col1={"#555555"} col2={"#aaaaaa"} name={i18n("schoolclass9")}/>
            <div/>
            <TitleList id="schoolclass9" url={'schoolclass.php?number=9&lang='+languageCode}
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <Arrow col1={"#ee0000"} col2={"#ee7777"} name={i18n("schoolclass10")}/>
            <div/>
            <TitleList id="schoolclass10" url={'schoolclass.php?number=10&lang='+languageCode}
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <Arrow col1={"#555555"} col2={"#aaaaaa"}  name={i18n("schoolclass11")}/>
            <div/>
            <TitleList id="schoolclass11" url={'schoolclass.php?number=11&lang='+languageCode}
                       onClick={(id) => onToggleResourceVisibility(id)} isBirthday={false}/>
            <div/>
        </div>
}

export default Curriculum;
